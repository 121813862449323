import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip } from "@mui/material";
import { Delete, Edit, Preview } from "@mui/icons-material";
import { useAuth } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { NotificationService } from "../../api/notification.service";
import { useAlert } from "../../hooks/useAlert";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const NotificationActions = ({ params }) => {
  const { t } = useTranslation();
  const { userInfo } = useAuth();
  const navigate = useNavigate();
  const showAlert = useAlert();
  const [open, setOpen] = useState(false);

  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleOnProcess = () => {
    deleteNotification(params.id);      
  };

  // refresh
  const refreshPage = () => {
    window.location.reload(true);
  };
  
  // delete notification
  const deleteNotification = async (id) => {
    if (!id) return;
    try {
      await NotificationService.delete(id, userInfo.jwt); 
      showAlert(t("Delete Notification successfully"), "success");
      refreshPage(); 
    } catch (error) {      
      console.log("Error delete", error);
    }
  };

  return (
    <>
      <Box>
        <Tooltip title={t("View notification")}>
          <IconButton onClick={() => navigate(`/notifications/${params.id}`)}>
            <Preview />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("Edit this notification")}>
          <IconButton
            onClick={() => navigate(`/notifications/${params.id}/edit`)}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("Delete this notification")}>
          <IconButton onClick={handleClickOpenDialog}>
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>

      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("ATTENTION!")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("Do you really want to delete this notification?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleCloseDialog}
          >
            {t("CANCEL")}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => handleOnProcess()}
            autoFocus
          >
            {t("YES")}
          </Button>
        </DialogActions>
      </Dialog>

      
    </>
  );
};

export default NotificationActions;
