import { Box, Button, Typography } from "@mui/material";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import SupportActions from "./SupportActions";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { SupportService } from "../../api/support.service";
import CircleIcon from "@mui/icons-material/Circle";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Support = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const [support, setSupport] = useState([]);
  const [rowId, setRowId] = useState();

  useEffect(() => {
    const fetchSupportData = async () => {
      const data = await SupportService.getAll();
      setSupport(data);      
    };

    fetchSupportData();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "userName",
      headerName: "Username",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "userEmail",
      headerName: "User Email",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "themeMessage",
      headerName: "Theme Message",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "userMessage",
      headerName: "User Message",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "completed",
      headerName: "Completed",
      flex: 1,
      type: "boolean",
    },
    {
      field: "manager",
      headerName: "Manager",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "status",
      minWidth: 100,
      headerName: "Status",
      flex: 1,
      headerAlign: "center",
      align: "left",
      // type: "singleSelect",
      // valueOptions: ["pending", "processing", "ready"],      
      renderCell: ({ row: { status } }) => {
        return (
          <>
            <CircleIcon
              sx={{
                fontSize: 13,
                color:
                  status === "pending"
                    ? colors.redAccent[600]
                    : status === "processing"
                    ? colors.blueAccent[600]
                    : colors.greenAccent[600],
              }}
            />

            <Typography sx={{ ml: 1, fontSize: 12 }}>
              {status}
            </Typography>
          </>
          
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created at",
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: "Updated at",
      flex: 1,
    },
    {
      field: "actions",
      minWidth: 120,
      headerName: "Actions",
      type: "actions",
      renderCell: (params) => (
        <SupportActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("SUPPORTS")} subtitle={t("List of Supports")} />

        <Box>
          <Button
            onClick={() => navigate(`/supports/create`)}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}            
          >
            <AddOutlinedIcon sx={{ mr: "10px" }} />
           {t("Create Support")}
          </Button>
        </Box>
      </Box>

      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            display: "flex",
            alignItems: "center",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          '.MuiDataGrid-booleanCell[data-value="true"]': {
            color: `${colors.greenAccent[500]} !important `,
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-container--top [role=row]": {
            backgroundColor: `${colors.blueAccent[700]} !important`,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={support}
          columns={columns}
          getRowId={(row) => row.id}
          onCellEditStart={(params) => setRowId(params.id)}
          // onCellClick={(params) => setRowId(params.id)}
          // components={{ Toolbar: GridToolbar }}
          slots={{
            toolbar: GridToolbar,
          }}
        />
      </Box>
    </Box>
  );
};

export default Support;
