import { useContext, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { tokens } from "../../theme";
import { Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import Header from "../../components/Header";
import AccountOverview from "./AccountOverview";
import AccountTransactions from "./AccountTransactions";
import AccountHistory from "./AccountHistory";
import AccountOrders from "./AccountOrders";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import { CoinContext } from "../../providers/CoinProvider";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AccountView = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const { accountsApp } = useContext(CoinContext);

  const accountId = accountsApp?.find((c) => c.id === Number(id));

  // console.log("accountId:", accountId);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box m="20px">
      {/* <Box display="flex" justifyContent="space-between" alignItems="center"> */}

      {/* GRID */}

      <Grid container spacing={5} justifyContent="space-between">
        <Grid item>
          <Header title={t("ACCOUNTS")} subtitle={t("List of Accounts")} />
        </Grid>
        <Grid item>
          <Box>
            <Button
              sx={{
                backgroundColor: colors.primary[400],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "12px 15px",
                minWidth: "245px",
              }}
            >
              <CurrencyExchangeIcon sx={{ mr: "10px" }} />

              <Stack direction="row">
                <Typography sx={{ mr: "10px" }}>
                  {t("Price (per 1")} {accountId?.cryptosymbol}):{" "}
                </Typography>
                <Typography
                  sx={{ fontWeight: "800", color: colors.greenAccent[500] }}
                >
                  {Number(accountId?.price).toFixed(2)} {accountId?.currency}
                </Typography>
              </Stack>
            </Button>
          </Box>
        </Grid>
        <Grid item>
          <Box>
            <Button
              sx={{
                backgroundColor: colors.primary[400],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "12px 15px",
                minWidth: "245px",
              }}
            >
              <AccountBalanceOutlinedIcon sx={{ mr: "10px" }} />

              <Stack direction="row">
                <Typography sx={{ mr: "10px" }}>
                  {t("Balance")} ({accountId?.cryptosymbol}):{" "}
                </Typography>
                <Typography
                  sx={{ fontWeight: "800", color: colors.greenAccent[500] }}
                >
                  {accountId?.cryptosymbol === "USDM"
                    ? Number(accountId?.cryptobalance).toFixed(2)
                    : Number(accountId?.cryptobalance).toFixed(6)}{" "}
                  {accountId?.cryptosymbol}
                </Typography>
              </Stack>
            </Button>
          </Box>
        </Grid>
        <Grid item>
          <Box>
            <Button
              sx={{
                backgroundColor: colors.primary[400],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "12px 15px",
                minWidth: "245px",
              }}
            >
              <AccountBalanceOutlinedIcon sx={{ mr: "10px" }} />

              <Stack direction="row">
                <Typography sx={{ mr: "10px" }}>Balance (USD): </Typography>
                <Typography
                  sx={{ fontWeight: "800", color: colors.greenAccent[500] }}
                >
                  {Number(accountId?.actualbalance).toFixed(2)}{" "}
                  {accountId?.currency}
                </Typography>
              </Stack>
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* </Box> */}

      <Box sx={{ flex: 1 }} mt="40px">
        <Box sx={{ maxWidth: "100%", borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{ background: colors.blueAccent[600] }}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab
              label={t("Overview")}
              {...tabProps(0)}
              sx={{
                "& .Mui-selected": {
                  color: `${colors.redAccent[700]} !important`,
                },
              }}
            />
            <Tab label={t("Send Transaction")} {...tabProps(1)} />
            <Tab label={t("History")} {...tabProps(2)} />
            <Tab label={t("Orders")} {...tabProps(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <AccountOverview />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <AccountTransactions />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <AccountHistory />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <AccountOrders />
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default AccountView;
