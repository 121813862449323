import React, { useContext, useEffect, useState } from "react";
import useIdle from "../../hooks/useIdleTimer";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { AuthContext } from "../../providers/AuthProvider";
import { useTranslation } from "react-i18next";

const IdleTimer = () => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);

  const handleIdle = () => {
    setShowModal(true); //show modal
    setRemainingTime(120); //set 120 seconds as time remaining (logout)
  };

  const { isIdle } = useIdle({ onIdle: handleIdle, idleTime: 5 });
  // 5 min или, например, 0.3min = 18sec
  // указывает продолжительность (в минутах), по истечении которой пользователь
  // считается бездействующим. В данном случае установлено значение 0.3 минут
  // (или 18 секунд).
  console.log("is user idle?", isIdle);

  useEffect(() => {
    let interval;

    if (isIdle && showModal) {
      interval = setInterval(() => {
        setRemainingTime(
          (prevRemainingTime) =>
            prevRemainingTime > 0 ? prevRemainingTime - 1 : 0 //reduces the second by 1
        );
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isIdle, showModal]);

  useEffect(() => {
    if (remainingTime === 0 && showModal) {
      setShowModal(false);
      logout();
    }
  }, [logout, remainingTime, showModal]); // this is responsoble for logging user out after timer is down to zero and they have not clicked anything

  const handleLogOut = () => {
    setShowModal(false);
    logout();
  };

  const handleStayLoggedIn = () => {
    setShowModal(false);
  };

  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + " m " + (seconds < 10 ? "0" : "") + seconds;
  }

  return (
    <>
      {/* handle isIdle for the modal */}
      {isIdle && showModal && (
        <Dialog
          open={showModal}
          onClose={handleLogOut}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            backdropFilter: "blur(5px)",
          }}
        >
          <DialogTitle id="alert-dialog-title" variant="h2">
            {t("Still with us?")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" component={"span"}>
              <Typography mt={1}>
                {t("Privacy is essential, and you've been gone a while.")}
              </Typography>

              <Stack
                direction="row"
                spacing={1}
                sx={{ alignItems: "flex-end" }}
              >
                <Typography> {t("We will log you out")}</Typography>
                <Typography color="secondary" variant="h4">
                  {millisToMinutesAndSeconds(remainingTime * 1000)} s
                </Typography>
                <Typography>
                  {t("unless you confirm you're still with us.")}
                </Typography>
              </Stack>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={handleLogOut}
              sx={{ gap: 1 }}
            >
             {t("LOGOUT NOW")}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleStayLoggedIn}
              autoFocus
            >
              {t("CONTINUE WORKING")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default IdleTimer;
