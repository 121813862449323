import { Box, Button, Divider, TextField, useMediaQuery } from "@mui/material";
import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { NotificationService } from "../../api/notification.service";
import { useTranslation } from "react-i18next";

const NotificationView = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [notification, setNotification] = useState();

  useEffect(() => {
    if (!id) return;
    const fetchNotificationData = async (id) => {
      const data = await NotificationService.getById(id);
      setNotification(data);
    };

    fetchNotificationData(id);
  }, [id]);

  if (!notification) return;

  return (
    <Box m="20px">
      <Header title={t("VIEW NOTIFICATION")} subtitle={t("View Notification")} />
      <Box
        mt="40px"
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          type="text"
          label="Id"
          value={notification.id}
          name="id"
          sx={{ gridColumn: "span 2" }}
          disabled
        />
        <Divider sx={{ gridColumn: "span 4" }}></Divider>
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          type="text"
          label={t("Title")}
          value={notification.title}
          name="title"
          sx={{ gridColumn: "span 2" }}
          disabled
        />
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          type="text"
          label={t("Details")}
          value={notification.details}
          name="details"
          sx={{ gridColumn: "span 4" }}
          disabled
        />
      </Box>
      <Box display="flex" justifyContent="end" mt="20px" gap={2}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => navigate(-1)}
        >
          {t("Go Back")}
        </Button>
      </Box>
    </Box>
  );
};

export default NotificationView;
