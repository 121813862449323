import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAuth } from "./AuthProvider";
import { io } from "socket.io-client";
import Peer from "simple-peer";
import axios from "axios";
import { MessageService } from "../api/message.service";

export const ChatContext = createContext();

export const useChatContext = () => {
  return useContext(ChatContext);
};

export const ChatProvider = ({ children }) => {
  const { userInfo } = useAuth();
  const [conversations, setConversations] = useState([]);
  const [filtred, setFilteredData] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [markAsRead, setMarkAsRead] = useState([]);
  const [socket, setSocket] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [playSound, setPlaySound] = useState(true);
  //videochat
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [stream, setStream] = useState(null);
  const [name, setName] = useState("");
  const [call, setCall] = useState({});
  const [me, setMe] = useState("");

  const myVideo = useRef();
  const userVideo = useRef();
  const connectionRef = useRef();

  // console.log("onlineUsersApp:", onlineUsers);

  //==========message==================
  const updateMessages = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_STRAPI_URL}/api/messages?filters[conversation][id]=${selectedConversation?.id}&populate=*`
      );
      const res = response.data.data;
      // console.log("updateMessages", res);
      setMessages(res);
    } catch (error) {
      console.error(error.message);
    }
  }, [selectedConversation]);

  useEffect(() => {
    const getMessages = async () => {
      if (!selectedConversation?.id) return;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_STRAPI_URL}/api/messages?filters[conversation][id]=${selectedConversation?.id}&populate=*`
        );
        const res = response.data.data;
        // console.log("dataMessages", res);
        setMessages(res);
      } catch (error) {
        console.error(error.message);
      }
    };

    getMessages();
  }, [selectedConversation, setMessages]);

  //==========conversation==================
  const updateConversations = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_STRAPI_URL}/api/conversations?filters[members][id]=${userInfo?.user.id}&populate=*`
      );
      const res = response.data.data;

      // Сортировка разговоров по дате последнего сообщения или дате создания
      const sortedConversations = res.sort((a, b) => {
        const aMessages = a.attributes.messages.data;
        const bMessages = b.attributes.messages.data;

        // Если у разговоров есть сообщения, то сортировать по дате последнего сообщения
        const aLastMessageDate = aMessages.length
          ? new Date(aMessages[aMessages.length - 1].attributes.createdAt)
          : new Date(a.attributes.createdAt);

        const bLastMessageDate = bMessages.length
          ? new Date(bMessages[bMessages.length - 1].attributes.createdAt)
          : new Date(b.attributes.createdAt);

        // Сортировка по убыванию (новые вверху)
        return bLastMessageDate - aLastMessageDate;
      });

      setConversations(sortedConversations);
      setFilteredData(sortedConversations);
    } catch (error) {
      console.log(error);
    }
  }, [userInfo?.user.id]);

  //socket init
  useEffect(() => {
    if (userInfo) {
      const socket = io(`${process.env.REACT_APP_BASE_URL_SERVICES}`, {
        query: {
          userId: userInfo?.user.id,
        },
      });

      setSocket(socket);

      // socket.on() is used to listen to the events. can be //used both on client and server side
      if (socket === null) return;
      socket.emit("addNewUser", userInfo?.user.id);

      socket.on("getOnlineUsers", (user) => {
        setOnlineUsers(user);
      });

      // Обновляем список онлайн пользователей
      socket.on("updateOnlineUsers", (user) => {
        setOnlineUsers(user);
      });

      socket.on("me", (id) => setMe(id));

      socket.on("callUser", ({ from, name: callerName, signal }) => {
        console.log(from, name, signal);
        setCall({ isReceivingCall: true, from, name: callerName, signal });
      });

      return () => socket.close();
    } else {
      if (socket) {
        socket.close();
        setSocket(null);
      }
    }
  }, [name, userInfo]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (socket) {
        socket.emit("heartbeat", userInfo?.user.id);
      }
    }, 30000); // 30 секунд

    return () => clearInterval(interval);
  }, [socket, userInfo?.user.id]);

  //TODO
  useEffect(() => {
    if (!markAsRead) return;

    const getMessagesForMark = async () => {
      if (!userInfo?.jwt) return;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_STRAPI_URL}/api/messages?filters[conversation][id]=${markAsRead.conversationId}&filters[receiver][id]=${markAsRead.receiverId}=populate=*`
        );
        const messagesForMark = response.data.data;
        // console.log("dataMessagesForMark", messagesForMark);

        if (messagesForMark.length > 0) {
          let ids = await messagesForMark.map((m) => m.id);
          const data = {
            isRead: true,
          };

          //error for exit
          for (let i = 0; i < messagesForMark.length; i++) {
            try {
              await MessageService.update(ids[i], data, userInfo?.jwt);
            } catch (error) {
              console.log("Error update messagesForMark", error);
            }
          }
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    getMessagesForMark();
  }, [markAsRead, userInfo?.jwt]);

  // ========= Video Call Logic =============
  const answerCall = () => {
    setCallAccepted(true);

    const peer = new Peer({ initiator: false, trickle: false, stream });

    peer.on("signal", (data) => {
      socket.emit("answerCall", { signal: data, to: call.from });
    });

    peer.on("stream", (currentStream) => {
      if (userVideo.current) {
        userVideo.current.srcObject = currentStream;
      }
    });

    try {
      peer.signal(call.signal);
    } catch (error) {
      console.error("Error during peer signaling: ", error);
    }

    connectionRef.current = peer;
  };

  const callUser = (id) => {
    const peer = new Peer({ initiator: true, trickle: false, stream });

    peer.on("signal", (data) => {
      socket.emit("callUser", {
        userToCall: id,
        signalData: data,
        from: me,
        name,
      });
    });

    peer.on("stream", (currentStream) => {
      if (userVideo.current) {
        userVideo.current.srcObject = currentStream;
      }
    });

    socket.on("callAccepted", (signal) => {
      setCallAccepted(true);

      try {
        peer.signal(signal);
      } catch (error) {
        console.error("Error during signal acceptance: ", error);
      }
    });

    connectionRef.current = peer;
  };

  const leaveCall = () => {
    setCallEnded(true);

    try {
      if (connectionRef.current) {
        connectionRef.current.destroy();
      }
    } catch (error) {
      console.log("Error while destroying peer connection: ", error);
    }

    // window.location.reload();

    // setStream(null);
    // setCall({});
    // setCallAccepted(false);
    // setCallEnded(false);
  };

  return (
    <ChatContext.Provider
      value={{
        conversations,
        setConversations,
        updateConversations,
        filtred,
        setFilteredData,
        selectedConversation,
        setSelectedConversation,
        messages,
        setMessages,
        updateMessages,
        socket,
        onlineUsers,
        newMessage,
        setNewMessage,
        notifications,
        setNotifications,
        markAsRead,
        setMarkAsRead,
        playSound,
        setPlaySound,
        call,
        setCall,
        callAccepted,
        myVideo,
        userVideo,
        stream,
        setStream,
        name,
        setName,
        callEnded,
        me,
        setMe,
        callUser,
        leaveCall,
        answerCall,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
