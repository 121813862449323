import axios from "axios";

export const AccountService = {
  async getAll() {
    const response = await axios.get(
      `${process.env.REACT_APP_STRAPI_URL}/api/accounts`
    );

    const res = response.data.data;

    const data = res.map((item) => ({
      id: item.id,
      nameAccount: item.attributes.nameAccount,
      numberAccount: item.attributes.numberAccount,
      pathAccount: item.attributes.pathAccount,
      coin: item.attributes.coin,
      symbol: item.attributes.symbol,
      imgsrc: item.attributes.imgsrc,
      privateKey: item.attributes.privateKey,
      contractAddress: item.attributes.contractAddress, 
      isActive: item.attributes.isActive, 
      createdAt: new Date(item.attributes.createdAt).toLocaleString(),        
    }));

    return data;
  },

  async getById(id) {
    const response = await axios.get(
      `${process.env.REACT_APP_STRAPI_URL}/api/accounts/${id}`
    );
    const res = response.data.data;

    const data = {     
      id: res.id,
      nameAccount: res.attributes.nameAccount,
      numberAccount: res.attributes.numberAccount,
      pathAccount: res.attributes.pathAccount,
      coin: res.attributes.coin,
      symbol: res.attributes.symbol,
      imgsrc: res.attributes.imgsrc,
      privateKey: res.attributes.privateKey,
      contractAddress: res.attributes.contractAddress, 
      isActive: res.attributes.isActive, 
    };

    return data;
  },

  async create(data, jwt) {
    const response = await axios.post(
      `${process.env.REACT_APP_STRAPI_URL}/api/accounts`,
      { data },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${jwt}`,
        },
      }
    );

    return response.data;
  },

  async update(id, data, jwt) {   
    const response = await axios.put(
      `${process.env.REACT_APP_STRAPI_URL}/api/accounts/${id}`,
       {data},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${jwt}`,
        },
      }
    );

    return response.data;
  },

  async delete(id, jwt) {
    const response = await axios.delete(
      `${process.env.REACT_APP_STRAPI_URL}/api/accounts/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${jwt}`,
        },
      }
    );

    return response.data;
  },
};
