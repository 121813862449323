import React, { useState, useContext } from "react";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Container,
  Paper,
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Assignment, Phone, PhoneDisabled } from "@mui/icons-material";
import { styled } from "@mui/system";

import { ChatContext } from "../../providers/ChatProvider";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Стилизация компонентов с использованием `styled`
const Root = styled("form")({
  display: "flex",
  flexDirection: "column",
});

const GridContainer = styled(Grid)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column",
  },
}));

const ContainerStyled = styled(Container)(({ theme }) => ({
  width: "600px",
  margin: "35px 0",
  padding: 0,
  [theme.breakpoints.down("xs")]: {
    width: "80%",
  },
}));

const Margin = styled("div")({
  marginTop: 20,
});

const Padding = styled("div")({
  padding: 20,
});

const PaperStyled = styled(Paper)(({ theme }) => ({
  padding: "10px 20px",
  border: `2px solid ${theme.palette.divider}`,
}));

const Options = ({ children }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const {
    me,
    callAccepted,
    name,
    setName,
    myVideo,
    callEnded,
    leaveCall,
    callUser,
    stream,
    setStream,
  } = useContext(ChatContext);
  const [idToCall, setIdToCall] = useState("");

  const handleClickHang = () => {
    leaveCall();
    stopStream();
    navigate("/chat");
    window.location.reload();
  };

  const stopStream = async () => {
    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop(); 
      });
      if (myVideo.current) {
        myVideo.current.srcObject = null;
      }
      setStream(null); 
    }
  };

  return (
    <ContainerStyled>
      <PaperStyled elevation={10}>
        <Root noValidate autoComplete="off">
          <GridContainer container>
            <Grid item xs={12} md={6}>
              <Padding>
                <Typography gutterBottom variant="h6">
                  {t("Account Info")}
                </Typography>
                <TextField
                  label={t("Your Name")}
                  value={name}
                  color="secondary"
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                />
                <CopyToClipboard text={me}>
                  <Margin>
                    <Button
                      variant="contained"
                      sx={{ background: colors.greenAccent[600] }}
                      fullWidth
                      startIcon={<Assignment fontSize="large" />}
                    >
                      {t("Copy Your ID")}
                    </Button>
                  </Margin>
                </CopyToClipboard>
              </Padding>
            </Grid>
            <Grid item xs={12} md={6}>
              <Padding>
                <Typography gutterBottom variant="h6">
                  {t("Make a call")}
                </Typography>
                <TextField
                  label={t("ID to call")}
                  value={idToCall}
                  color="secondary"
                  onChange={(e) => setIdToCall(e.target.value)}
                  fullWidth
                />
                {callAccepted && !callEnded ? (
                  <Margin>
                    <Button
                      variant="contained"
                      sx={{ background: colors.redAccent[600] }}
                      startIcon={<PhoneDisabled fontSize="large" />}
                      fullWidth
                      onClick={handleClickHang}
                    >
                    {t("Hang Up")}
                    </Button>
                  </Margin>
                ) : (
                  <Margin>
                    <Button
                      variant="contained"
                      sx={{ background: colors.greenAccent[600] }}
                      startIcon={<Phone fontSize="large" />}
                      fullWidth
                      onClick={() => callUser(idToCall)}
                    >
                      {t("Call")}
                    </Button>
                  </Margin>
                )}
              </Padding>
            </Grid>
          </GridContainer>
        </Root>
        {children}
      </PaperStyled>
    </ContainerStyled>
  );
};

export default Options;
