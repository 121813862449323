import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import {
  fetchBinanceData,
  fetchCoinRatesAppDatabase,
} from "../../api/coin.service";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import { useAuth } from "../../providers/AuthProvider";
import { useAlert } from "../../hooks/useAlert";
// import LoadingBox from "../../components/LoadingBox";

const UserAssets = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const showAlert = useAlert();
  const { userToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [totalBalance, setTotalBalance] = useState(0);
  const [coinPrice, setCoinPrice] = useState();

  useEffect(() => {
    preload();
  }, []);

  //get balance assets
  const fetchAccountBalance = async (account, symbol) => {
    let coin;

    if (symbol === "USDT") {
      coin = "token";
    } else {
      coin = symbol.toLowerCase();
    }

    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL_SERVICES}/api/balance_${coin}address`,
        { account }
      )
      .then((res) => {
        console.log(`Balance ${coin}:`, res.data);
        return res.data;
      })
      .catch((e) => {
        if (e.message === "Network Error") {
          showAlert("Network Error", "error");
        }

        console.log(`Error ${e}`);
      });
  };

  const preload = async () => {
    setLoading(true);

    //fetch
    const [res_binance, res_coinRates, res_assets] = await Promise.all([
      fetchBinanceData(),
      fetchCoinRatesAppDatabase(userToken),
      fetchAssetsDatabase(userToken),
    ]);

    let resCoinRates;
    if (res_coinRates) {
      resCoinRates = res_coinRates.map((item) => ({
        symbol: item.attributes.symbol,
        price: String(item.attributes.price),
      }));
    } else {
      resCoinRates = [];
    }

    const res_price_common = res_binance.concat(resCoinRates);

    const format_price_common = res_price_common.map((item) => ({
      symbol: item.symbol.slice(0, -4), // delete USDT in pair
      price: item.price,
    }));

    setCoinPrice(format_price_common.slice());

    if (res_assets) {
      let total = 0;

      const assets = await Promise.all(
        res_assets.map(async (item, index) => {
          const symbol = item.attributes.symbol;
          const price =
            format_price_common.find((item) => item.symbol.includes(symbol))
              ?.price || 0;

          const balance = await fetchAccountBalance(
            item.attributes.account,
            symbol
          );

          total += balance * price;

          return {
            id: item.id,
            name: item.attributes.coin,
            account: item.attributes.account,
            cryptobalance: balance,
            cryptosymbol: symbol,
            currency: "$",
            imgsrc: item.attributes.imgsrc,
            actualbalance: price * balance,
            percentage: "",
            decreased: true,
            difference: "8.42",
            mywallet: false,
            encryptPk: item.attributes.privateKey,
            contractAddress: item.attributes.contractAddress,
            isShow:
              item.attributes.isShow !== null ? item.attributes.isShow : true,
            price: price,
          };
        })
      );

      console.log("assets:", assets);

      setAssets(assets);
      setTotalBalance(total);
      setLoading(false);
    }
  };

  const fetchAssetsDatabase = async () => {
    if (!userToken) return;

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${userToken}`,
        },
      };

      const json = await fetch(
        `${process.env.REACT_APP_STRAPI_URL}/api/assets?filters[user][id][$eq]=${id}`,
        config
      );
      const res = await json.json();
      return res.data;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "imgsrc",
      headerName: "Img",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <img alt="coin" width="25px" height="25px" src={params.row.imgsrc} />
      ),
    },
    {
      field: "account",
      headerName: "Account",
      flex: 3,
      cellClassName: "name-column--cell",
    },
    {
      field: "cryptobalance",
      headerName: "Crypto Balance",
      flex: 1.5,
      headerAlign: "right",
      align: "right",
      cellClassName: "name-column--cell",
    },
    {
      field: "cryptosymbol",
      headerName: "Crypto Symbol",
      flex: 0.7,
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column--cell",
    },
    {
      field: "actualbalance",
      headerName: "Actual Balance",
      flex: 1.5,
      headerAlign: "right",
      align: "right",
      renderCell: (params) => (
        <Typography variant="body2">
          {params.row.actualbalance.toFixed(2)}
        </Typography>
      ),
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 0.7,
      headerAlign: "left",
      align: "left",
    },
  ];

  return (
    <>
      {loading ? (
        <Box sx={{ display: "flex" }}>
          <Typography variant="h5" color="secondary">
            {t("Wait, please...")}
          </Typography>

          {/* <LoadingBox /> */}
        </Box>
      ) : (
        <>
          <Grid container spacing={5} justifyContent="space-between">
            <Grid item>
              <Box>
                <Button
                  sx={{
                    backgroundColor: colors.primary[400],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "12px 15px",
                    minWidth: "225px",
                  }}
                >
                  <AccountBalanceOutlinedIcon sx={{ mr: "10px" }} />

                  <Stack direction="row">
                    <Typography sx={{ mr: "10px", fontWeight: "800" }}>
                      {t("Total Balance:")}{" "}
                    </Typography>
                    <Typography
                      sx={{ fontWeight: "800", color: colors.greenAccent[500] }}
                    >
                      {totalBalance && Number(totalBalance).toFixed(2)} $
                    </Typography>
                  </Stack>
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <Button
                  sx={{
                    backgroundColor: colors.primary[400],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "12px 15px",
                    minWidth: "225px",
                  }}
                >
                  <HubOutlinedIcon sx={{ mr: "10px" }} />

                  <Stack direction="row">
                    <Typography sx={{ mr: "10px", fontWeight: "800" }}>
                      Net:{" "}
                    </Typography>
                    {process.env.NODE_ENV === "development" ? (
                      <Typography
                        sx={{
                          fontWeight: "800",
                          color: colors.greenAccent[500],
                        }}
                      >
                        {t("Sepolia Testnet")}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontWeight: "800",
                          color: colors.greenAccent[500],
                        }}
                      >
                        {t("Mainnet")}
                      </Typography>
                    )}
                  </Stack>
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Box
            m="20px 0 0 0"
            height="50vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
                display: "flex",
                alignItems: "center",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-container--top [role=row]": {
                backgroundColor: `${colors.blueAccent[800]} !important`,
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[800],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            <DataGrid rows={assets} columns={columns} />
          </Box>

          <Box display="flex" justifyContent="end" mt="20px" gap={1}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => navigate(-1)}
            >
              {t("Go Back")}
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default UserAssets;
