import React, { useState } from "react";
import { useAlert } from "../../hooks/useAlert";
import axios from "axios";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
} from "@mui/material";
import { useAuth } from "../../providers/AuthProvider";
import { useTranslation } from "react-i18next";

const UploadAvatar = ({
  userId,
  username,
  userToken,
  avatarUrl,
  setIsUserUpdated,
}) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [file, setFile] = useState("");
  const showAlert = useAlert();
  const { userInfo, setUserAvatar } = useAuth();
 
  const toggle = () => {
    setModal(!modal);
  };

  const handleCloseDialog = () => {
    setModal(false);
  };

  const handleFileChange = ({ target: { files } }) => {
    console.log({ target: { files } });

    if (files?.length) {
      const { type } = files[0];
      if (type === "image/png" || type === "image/jpeg") {
        setFile(files[0]);
      } else {
        showAlert(t("Accept only png and jpeg image types are allowed*"), "error");
      }
    }
  };

  const updateUserAvatarId = async (avatarId, avatarUrl) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_STRAPI_URL}/api/users/${userId}`,
        { avatarId, avatarUrl },
        {
          headers: {
            Authorization: `bearer ${userToken}`,
          },
        }
      );
      setIsUserUpdated(true);

      if (userInfo.user.id === userId) {
        localStorage.setItem("userAvatar", avatarUrl);
      }
    } catch (error) {}
  };

  const handleSubmit = async () => {
    if (!file) {
      showAlert("File is required", "error");
      return;
    }

    try {
      const files = new FormData();
      files.append("files", file, `avatar_${userId}`);

      const {
        data: [{ id, url }],
      } = await axios.post(
        `${process.env.REACT_APP_STRAPI_URL}/api/upload`,
        files,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `bearer ${userToken}`,
          },
        }
      );
      updateUserAvatarId(id, url);
      setFile("");
      setModal(false);

      if (userInfo.user.id === userId) {
        setUserAvatar(url);
      }

      showAlert(t("Update User Avatar successfully"), "success");
    } catch (error) {
      console.log({ error });
      showAlert(t("Update User Avatar error"), "error");
    }
  };

  return (
    <Box>
      <Button color="secondary" variant="contained" onClick={toggle}>
        {t("Change avatar")}
      </Button>

      <Dialog open={modal} onClose={handleCloseDialog}>
        <DialogTitle>
          {t("Change avatar (PNG, JPEG)")}
          {/* {`${userAvatar === "null" ? "Upload" : "Change"} avatar (PNG, JPEG)`}{" "} */}
        </DialogTitle>
        <DialogContent>
          <label htmlFor="contained-button-file">
            <Input
              accept="image/png, image/jpeg"
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleFileChange}
            />
          </label>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleCloseDialog}
          >
            {t("CANCEL")}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSubmit}
            autoFocus
          >
            {t("Upload")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UploadAvatar;
