// import axios from "axios";

export const fetchPrivatData = async () => {
  try {
    const json = await fetch(
      "https://api.privatbank.ua/p24api/pubinfo?json&exchange&coursid=5"
    );
    const res = await json.json();
    return res;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchBinanceData = async () => {
  try {
    const json = await fetch(
      'https://api.binance.com/api/v3/ticker/price?symbols=["BTCUSDT","ETHUSDT","ETCUSDT"]'
    );
    const res = await json.json();
    return res;
  } catch (err) {
    console.error(err);
    return false;
  }
};

//get accounts from Database
export const fetchAccountAppDatabase = async (userToken) => {
  if (!userToken) return;

  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${userToken}`,
      },
    };

    const json = await fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/accounts`,
      config
    );

    const res = await json.json();

    return res.data;
  } catch (err) {
    console.error(err);
    return false;
  } finally {
  }
};

//get accounts from Database
export const fetchCoinRatesAppDatabase = async (userToken) => {
  if (!userToken) return;

  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${userToken}`,
      },
    };

    const json = await fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/coin-rates`,
      config
    );

    const res = await json.json();

    return res.data;
  } catch (err) {
    console.error(err);
    return false;
  } finally {
  }
};
