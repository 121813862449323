import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useAlert } from "../../hooks/useAlert";
import { useAuth } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { CurrencyRateService } from "../../api/currencyRate.service";
import { useTranslation } from "react-i18next";

const CurrencyRateCreate = () => {
  const { t } = useTranslation();
  const { userInfo } = useAuth();
  const navigate = useNavigate();
  const showAlert = useAlert();

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = (values) => {
    console.log(values);
    createNewCurrencyRate(values);
  };

  // add new CurrencyRate
  const createNewCurrencyRate = async (data) => {
    try {
      await CurrencyRateService.create(data, userInfo.jwt);
      showAlert(t("Create Currency Rate successfully"), "success");
      navigate("/currency-rates");
    } catch (error) {
      showAlert(t("Create Currency Rate error"), "error");
      console.log("Error create", error);
    }
  };

  return (
    <Box m="20px">
      <Header
        title={t("CREATE Currency Rate")} subtitle={t("Create a New Currency Rate")}
      />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              mt="40px"
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Base_ccy")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.base_ccy}
                name="base_ccy"
                error={!!touched.base_ccy && !!errors.base_ccy}
                helperText={touched.base_ccy && errors.base_ccy}
                sx={{ gridColumn: "span 2" }}
              />
              {/* <TextField
                fullWidth
                variant="filled"
                type="text"
                label="City"
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.city}
                name="city"
                error={!!touched.city && !!errors.city}
                helperText={touched.city && errors.city}
                sx={{ gridColumn: "span 2" }}
              /> */}

              <FormControl variant="filled" color="secondary" sx={{ gridColumn: "span 2" }}>
                <InputLabel id="city-label">{t("City")}</InputLabel>
                <Select
                  labelId="city-label"
                  id="city-select"
                  value={values.city}
                  onChange={handleChange}
                  label={t("City")}
                  name="city"
                  color="secondary"
                >
                  <MenuItem value="Dnipro">{t("Dnipro")}</MenuItem>
                  <MenuItem value="Kyiv">{t("Kyiv")}</MenuItem>
                  <MenuItem value="Lviv">{t("Lviv")}</MenuItem>
                  <MenuItem value="Warshava">{t("Warshava")}</MenuItem>
                </Select>
              </FormControl>

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Flag")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.flag}
                name="flag"
                error={!!touched.flag && !!errors.flag}
                helperText={touched.flag && errors.flag}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Ccy")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.ccy}
                name="ccy"
                error={!!touched.ccy && !!errors.ccy}
                helperText={touched.ccy && errors.ccy}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="number"
                label={t("Buy")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.buy}
                name="buy"
                error={!!touched.buy && !!errors.buy}
                helperText={touched.buy && errors.buy}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="number"
                label={t("Sale")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.sale}
                name="sale"
                error={!!touched.sale && !!errors.sale}
                helperText={touched.sale && errors.sale}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>

            <Box display="flex" justifyContent="end" mt="20px" gap={1}>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => navigate(-1)}
              >
                {t("Go Back")}
              </Button>
              <Button type="submit" color="secondary" variant="contained">
                {t("Create New Currency Rate")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  base_ccy: yup.string().required("required"),
  city: yup.string().required("required"),
  flag: yup.string().required("required"),
  ccy: yup.string().required("required"),
  buy: yup.string().required("required"),
  sale: yup.string().required("required"),
});

const initialValues = {
  base_ccy: "",
  city: "",
  flag: "",
  ccy: "",
  buy: "",
  sale: "",
};

export default CurrencyRateCreate;
