import React from "react";
import SidebarChat from "../../components/sidebarchat/SidebarChat";
import MessageContainer from "../../components/messages/MessageContainer";
import { Box, Container, CssBaseline } from "@mui/material";
import Header from "../../components/Header";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";

const Chat = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <>
      <Box m="20px">
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title={t("CHAT")} subtitle={t("Chat for Team and Users")} />
        </Box>
      </Box>
      {/* CHAT CONTAINER */}

      <CssBaseline />
      <Container sx={{ minWidth: "750px" }}>
        <Box
          m="40px"
          display="flex"
          sx={{ bgcolor: colors.primary[400], height: "60vh" }}
        >
          <SidebarChat />
          <MessageContainer />
        </Box>
      </Container>
    </>
  );
};

export default Chat;
