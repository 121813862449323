import React from "react";
import Conversations from "./Conversations";
import LogoutButton from "./LogoutButton";
// import SearchInput from "./SearchInput";
import { Box } from "@mui/material";

const SidebarChat = () => {
  return (
    <Box p={2} sx={{ position: "relative", width: "280px" }}>
      {/* <SearchInput /> */}      
      <Conversations />
      <LogoutButton />
    </Box>
  );
};

export default SidebarChat;
