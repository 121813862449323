import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Delete, Edit, Preview } from "@mui/icons-material";
import { useAuth } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../hooks/useAlert";
import { AccountService } from "../../api/account.service";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const AccountActions = ({ params }) => {
  const { t } = useTranslation();
  const { userInfo } = useAuth();
  const navigate = useNavigate();
  const showAlert = useAlert();
  const [open, setOpen] = useState(false);

  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleOnProcess = () => {
    deleteAccount(params.id);      
  };

  // refresh
  const refreshPage = () => {
    window.location.reload(true);
  };

  // delete User
  const deleteAccount = async (id) => {
    if (!id) return;

    try {
      await AccountService.delete(id, userInfo.jwt);
      showAlert(t("Delete Account successfully"), "success");
      refreshPage();
    } catch (error) {
      console.log("Error delete", error);
    }
  };

  return (
    <>
      <Box>
        <Tooltip title={t("View Account")}>
          <IconButton onClick={() => navigate(`/accounts/${params.id}`)}>
            <Preview />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("Edit this Account")}>
          <IconButton onClick={() => navigate(`/accounts/${params.id}/edit`)}>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("Delete this Account")}>
          {/* <IconButton onClick={() => deleteAccount(params.id)}> */}
          <IconButton onClick={handleClickOpenDialog}>
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>

      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"ATTENTION!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("Do you really want to delete this account?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleCloseDialog}
          >
            {t("CANCEL")}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => handleOnProcess()}
            autoFocus
          >
            {t("YES")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AccountActions;
