import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputBase,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Conversation from "./Conversation";
import { useAuth } from "../../providers/AuthProvider";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import SearchIcon from "@mui/icons-material/Search";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { ChatContext } from "../../providers/ChatProvider";
import { UserService } from "../../api/user.service";
import { useAlert } from "../../hooks/useAlert";

const Conversations = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const showAlert = useAlert();
  const { userInfo } = useAuth();
  const {
    conversations,
    setConversations,
    updateConversations,
    filtred,
    setFilteredData,
    messages,
    updateMessages,
    setSelectedConversation,
    socket,
    markAsRead,
    setMarkAsRead,
  } = useContext(ChatContext);

  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);

  const onChangeInput = (value) => {
    setSearch(value);

    if (value === "") {
      setFilteredData(conversations);
    } else {
      filterData(value);
    }
  };

  const filterData = (query) => {
    const queryLowerCase = query.toLowerCase();
    const result = conversations.filter((item) => {
      const members = item.attributes.members.data;

      return members.some((member) =>
        member.attributes.username.toLowerCase().includes(queryLowerCase)
      );
    });

    setFilteredData(result);
  };

  const filterConversation = (query) => {
    const queryLowerCase = query.toLowerCase();
    const result = conversations.filter((item) => {
      const members = item.attributes.members.data;

      return members.some((member) =>
        member.attributes.username.toLowerCase().includes(queryLowerCase)
      );
    });

    return result;
  };

  const handleClickClose = () => {
    setFilteredData(conversations);
    setSearch("");
  };

  const handleClickAdd = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //TODO
  const handleClickConversation = (item) => {
    setSelectedConversation(item);
    setMarkAsRead({
      ...markAsRead,
      receiverId: userInfo?.user.id,
      conversationId: item.id,
    });
    // setUnread("");
  };

  const checkedConversation = (partner) => {
    const f_conversation = filterConversation(partner);

    if (f_conversation.length > 0) {
      showAlert(
        t("Conversation already exists!!! Search in contacts"),
        "error"
      );
    } else {
      createConversation(partner);
    }
  };

  const createConversation = async (partner) => {
    const partnerUser = users.find((user) => user.username === partner);

    if (!partnerUser) {
      showAlert(t("Partner not found!"), "error");
      return;
    }

    const partnerId = partnerUser.id;

    if (partnerId === userInfo?.user.id) {
      showAlert(t("You cannot create a conversation with yourself!"), "error");
      return;
    }

    const data = {
      members: [userInfo?.user.id, partnerId],
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_STRAPI_URL}/api/conversations?populate=*`,
        { data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${userInfo.jwt}`,
          },
        }
      );
      const newDataConversation = res.data.data;

      showAlert(t("Conversation created success"), "success");

      setConversations([...conversations, newDataConversation]);
      setFilteredData([...conversations, newDataConversation]);

      //socket OUT
      socket.emit("addConversation", newDataConversation);

      updateConversations();
    } catch (error) {
      console.log(error);
      showAlert(t("Conversation created error"), "error");
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (!userInfo?.user.id) return;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_STRAPI_URL}/api/users?filters[id][$ne]=${userInfo?.user.id}`
        );
        const res = response.data;
        setUsers(res);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUserData();
  }, []);

  //receive conversation, socket iN
  useEffect(() => {
    if (socket === null) return;

    socket.on("getConversation", (res) => {
      if (userInfo?.user.id !== res.attributes.members.data[1].id) return;

      setConversations((prev) => [...prev, res]);
      setFilteredData((prev) => [...prev, res]);

      //updateConversations();
      showAlert(t("Conversation created success"), "success");
    });

    return () => {
      socket.off("getConversation");
    };
  }, [
    setConversations,
    setFilteredData,
    socket,
    updateConversations,
    userInfo?.user.id,
  ]);

  //remove conversation, socket iN
  useEffect(() => {
    if (socket === null) return;

    // Проверка, есть ли пользователь в разговоре
    const isMember = conversations.some((conversation) =>
      conversation.attributes.members.data.some(
        (member) => member.id === userInfo?.user.id
      )
    );

    socket.on("getRemoveConversation", (res) => {
      if (!isMember) return;

      const f_conversations = conversations.filter((c) => c.id !== res.id);

      setConversations(f_conversations);
      setFilteredData(f_conversations);
      setSelectedConversation(null);

      updateConversations();
      showAlert(t("Delete conversation successfully"), "success");
    });

    return () => {
      socket.off("getRemoveConversation");
    };
  }, [
    conversations,
    setConversations,
    setFilteredData,
    socket,
    setSelectedConversation,
    updateMessages,
    updateConversations,
    userInfo?.user.id,
  ]);

  useEffect(() => {
    const getConversations = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_STRAPI_URL}/api/conversations?filters[members][id]=${userInfo?.user.id}&populate=*`
        );
        const res = response.data.data;
  
        // Сортировка разговоров по дате последнего сообщения или дате создания
        const sortedConversations = res.sort((a, b) => {
          const aMessages = a.attributes.messages.data;
          const bMessages = b.attributes.messages.data;
  
          // Если у разговоров есть сообщения, то сортировать по дате последнего сообщения
          const aLastMessageDate = aMessages.length
            ? new Date(aMessages[aMessages.length - 1].attributes.createdAt)
            : new Date(a.attributes.createdAt);
  
          const bLastMessageDate = bMessages.length
            ? new Date(bMessages[bMessages.length - 1].attributes.createdAt)
            : new Date(b.attributes.createdAt);
  
          // Сортировка по убыванию (новые вверху)
          return bLastMessageDate - aLastMessageDate;
        });
  
        setConversations(sortedConversations);
        setFilteredData(sortedConversations);
      } catch (error) {
        console.log(error);
      }
    };
    getConversations();
  }, [
    setConversations,
    setFilteredData,
    updateConversations,
    userInfo?.user.id,
    messages,
  ]);

  if (!filtred) return;

  return (
    <>
      {/* ====================SEARCH BAR======================= */}
      <form onSubmit={(e) => e.preventDefault()}>
        <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          borderRadius="3px"
          sx={{
            border: `2px solid ${colors.greenAccent[800]}`,
          }}
        >
          <IconButton type="button">
            <SearchIcon />
          </IconButton>

          <InputBase
            sx={{ flex: 1 }}
            placeholder={t("Search contact")}
            id="search"
            value={search}
            onChange={(e) => onChangeInput(e.target.value)}
          />

          {search.length > 0 ? (
            <Tooltip title={t("Reset")}>
              <IconButton type="button" onClick={handleClickClose}>
                <CloseOutlinedIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              <Tooltip title={t("Add conversation")} disableFocusListener>
                <IconButton
                  aria-hidden="false"
                  type="button"
                  color="secondary"
                  onClick={handleClickAdd}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      </form>

      {/* =====================Dialog=================== */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const username = formJson.username;
            // console.log(username);
            checkedConversation(username);
            handleClose();
          },
        }}
      >
        <DialogTitle>{t("CREATE CONVERSATION")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("Select contact to create a conversation (dialog)")}
          </DialogContentText>
          <Autocomplete
            color="secondary"
            disablePortal
            id="combo-box-demo"
            options={users.map((option) => option.username)}
            sx={{ width: 300, height: 400, marginTop: "20px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Contact")}
                variant="outlined"
                color="secondary"
                name="username"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="outlined" onClick={handleClose}>
            {t("Cancel")}
          </Button>
          <Button type="submit" color="secondary" variant="contained">
            {t("Create conversation")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* ====================Conversations======================= */}
      <Box
        // gap={1}
        display="flex"
        flexDirection="column"
        mt={2}
        overflow="auto"
        height="80%"
        width="100%"
      >
        {filtred.length ? (
          filtred.map((item, index) => {
            return (
              <div key={index} onClick={() => handleClickConversation(item)} >
                <Conversation
                  conversation={item}
                  userId={userInfo?.user.id}
                  // isMe={item.attributes.members.data[0].id === userInfo.user.id}
                  // undread={undread}
                />
              </div>
            );
          })
        ) : (
          <Typography>{t("Sorry, nothing found!")}</Typography>
        )}
      </Box>
    </>
  );
};

export default Conversations;
