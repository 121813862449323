import { useTheme } from "@emotion/react";
import React from "react";
import { tokens } from "../../theme";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AccountOrders = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  return (
    <>
      <Box
        gridColumn="span 4"
        gridRow="span 2"
        backgroundColor={colors.primary[400]}
        overflow="auto"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={`4px solid ${colors.primary[500]}`}
          colors={colors.grey[100]}
          p="15px"
        >
          <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
            {t("No orders...")}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="end" mt="20px" gap={2}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => navigate(-1)}
        >
          {t("Go Back")}
        </Button>
      </Box>
    </>
  );
};

export default AccountOrders;
