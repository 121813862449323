import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  Typography,
} from "@mui/material";
import React from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TopSupportMenu = ({
  anchorSupportMenu,
  setAnchorSupportMenu,
  support,
  setShowBadgeSupport,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const handleCloseSupportMenu = () => {
    setAnchorSupportMenu(null);
  };

  return (
    <Menu
      anchorEl={anchorSupportMenu}
      open={Boolean(anchorSupportMenu)}
      onClose={handleCloseSupportMenu}
      onClick={handleCloseSupportMenu}
    >
      <Box ml={2}>
        <Typography
          onClick={() => setShowBadgeSupport(false)}
          sx={{ cursor: "pointer", color: colors.blueAccent[300] }}
        >
          {t("Read all")}
        </Typography>
      </Box>

      {support.map((item) => (
        <List key={item.id}>
          <ListItem
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => navigate(`/supports/${item.id}/edit`)}
              >
                <Edit sx={{ color: colors.greenAccent[500] }} />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: colors.greenAccent[500] }}>
                <MailOutlineIcon />
              </Avatar>
            </ListItemAvatar>

            <ListItemText>
              {item.themeMessage} {item.username}
            </ListItemText>
          </ListItem>
        </List>
      ))}
    </Menu>
  );
};

export default TopSupportMenu;
