import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useAlert } from "../hooks/useAlert";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userToken, setUserToken] = useState(localStorage.getItem("userToken"));
  const [userAvatar, setUserAvatar] = useState(
    localStorage.getItem("userAvatar")
  );
  const [userInfo, setUserInfo] = useState(null);
  const showAlert = useAlert();

  const register = (username, email, password) => {
    setIsLoading(true);

    axios
      .post(`${process.env.REACT_APP_STRAPI_URL}/api/auth/local/register`, {
        username,
        email,
        password,
      })
      .then((res) => {
        let userInfo = res.data;
        setIsLoading(false);
        console.log(userInfo);
        showAlert("Registration successfully!", "success");
      })
      .catch((e) => {
        console.log(`register error ${e}`);
        setIsLoading(false);
        showAlert("Register error", "error");
      });
  };

  const login = (email, password) => {
    const identifier = email;
    setIsLoading(true);

    axios
      .post(`${process.env.REACT_APP_STRAPI_URL}/api/auth/local`, {
        identifier,
        password,
      })
      .then((res) => {
        let userInfo = res.data;        

        let avatar = "";
        if (userInfo.user.avatarUrl !== null) {
          avatar = userInfo.user.avatarUrl;
        }

        setUserInfo(userInfo);
        setUserToken(userInfo.jwt);
        setUserAvatar(avatar);

        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        localStorage.setItem("userToken", userInfo.jwt);
        localStorage.setItem("userAvatar", avatar);

        setIsLoading(false);
      })
      .catch((e) => {
        console.log(`login error ${e}`);
        showAlert("Login or password is incorrect!", "error");
        setIsLoading(false);
      });
  };

  const logout = () => {
    setIsLoading(true);
    setUserToken(null);
    localStorage.removeItem("userInfo");
    localStorage.removeItem("userToken");
    localStorage.removeItem("userAvatar");
    setUserInfo(null);
    setUserAvatar(null);
    setIsLoading(false);
  };

  const isAuthenticated = async () => {
    try {
      setIsLoading(true);

      let userInfo = localStorage.getItem("userInfo");
      let userToken = localStorage.getItem("userToken");
      let userAvatar = localStorage.getItem("userAvatar");
      userInfo = JSON.parse(userInfo);

      if (userInfo) {
        setUserInfo(userInfo);
        setUserToken(userToken);
        setUserAvatar(userAvatar);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(`is logged in error ${e}`);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    isAuthenticated();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        userToken,
        userInfo,
        userAvatar,
        setUserAvatar,
        register,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
