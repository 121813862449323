import {
  Badge,
  Box,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import InterpreterModeOutlinedIcon from "@mui/icons-material/InterpreterModeOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useAuth } from "../../providers/AuthProvider";
import { ChatContext } from "../../providers/ChatProvider";
import Messages from "./Messages";
import TopChatMenu from "./TopChatMenu";
import LoadingBox from "../LoadingBox";
import { useNavigate } from "react-router-dom";

const MessageContainer = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { selectedConversation, setSelectedConversation, onlineUsers } =
    useContext(ChatContext);
  const { userInfo } = useAuth();
  const [anchorChatMenu, setAnchorChatMenu] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const partner = selectedConversation?.attributes.members.data.find(
    (member) => member.id !== userInfo?.user.id
  );

  const isOnline = onlineUsers.some((user) => user?.userId === partner?.id);

  const handleClick = () => {
    navigate("/videochat", { state: { partner } });
  };

  const handleClickMeeting = () => {
    //navigate("/intro-room", { state: { from: "/chat" } });
	window.open("https://mt.moneyshop.ua", "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    // cleanup function (unmounts)
    return () => setSelectedConversation(null);
  }, [setSelectedConversation]);

  return (
    <>
      {!selectedConversation ? (
        <NoChatSelected />
      ) : (
        <>
          <Box
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              flex: 1,
              mb: 1,
              mr: 2,
            }}
          >
            {/* Header container */}
            <Box
              mt={1}
              display="flex"
              justifyContent="space-between"
              sx={{ backgroundColor: colors.primary[400] }}
            >
              <Box></Box>
              <Box>
                <Typography align="center">
                  {partner?.attributes.username}
                </Typography>
                <Box align="center" mb={1}>
                  <Badge
                    color={isOnline ? "success" : "warning"}
                    overlap="circular"
                    badgeContent=" "
                    variant="dot"
                    sx={{
                      position: "absolute",
                      marginTop: "10px",
                      marginLeft: "-20px",
                    }}
                  />
                  <Typography
                    variant="subtitle2"
                    color={
                      isOnline ? colors.greenAccent[300] : colors.grey[400]
                    }
                    sx={{ fontWeight: "600", marginLeft: "16px" }}
                  >
                    {isOnline ? t("online") : t("offline")}
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Tooltip title={t("Meeting")} disableInteractive>
                  <IconButton onClick={handleClickMeeting}>
                    <InterpreterModeOutlinedIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title={t("VideoCall")} disableInteractive>
                  <IconButton onClick={handleClick}>
                    <VideocamOutlinedIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title={t("More")} disableFocusListener>
                  <IconButton
                    aria-hidden="false"
                    onClick={(e) => setAnchorChatMenu(e.currentTarget)}
                  >
                    <MoreHorizOutlinedIcon />
                  </IconButton>
                </Tooltip>
                <TopChatMenu
                  {...{
                    anchorChatMenu,
                    setAnchorChatMenu,
                    isLoading,
                    setIsLoading,
                  }}
                />
              </Box>
            </Box>
            <Divider color={colors.blueAccent[500]} />

            {isLoading ? <LoadingBox /> : <Messages />}
          </Box>
        </>
      )}
    </>
  );
};

export default MessageContainer;

const NoChatSelected = () => {
  const { userInfo } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
      >
        <Box display="flex" justifyContent="center">
          <Typography color="secondary" variant="h3" textAlign="center">
            {t("Welcome")} 👋, {userInfo ? userInfo?.user?.username : "guest"} !
          </Typography>
        </Box>
        <Box p={1} display="flex" justifyContent="center" textAlign="center">
          <Typography>{t("Select a chat to start messaging")}</Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <ForumOutlinedIcon sx={{ fontSize: "60px" }} color="secondary" />
        </Box>
      </Box>
    </>
  );
};
