import axios from "axios";

export const UserService = {
  async getAll() {
    const response = await axios.get(
      `${process.env.REACT_APP_STRAPI_URL}/api/users`
    );

    const res = response.data;

    const data = res.map((item) => ({
      id: item.id,
      username: item.username,
      email: item.email,
      confirmed: item.confirmed,
      blocked: item.blocked,
      avatarUrl: item.avatarUrl,
      phone: item.phone,
      firstName: item.firstName,
      lastName: item.lastName,
      age: item.age,
      is2fa: item.is2fa,
      isBioAuth: item.isBioAuth,
      isVerified: item.isVerified,
      isSubChatNotification: item.isSubChatNotification,
      createdAt: new Date(item.createdAt).toLocaleString(),
      updatedAt: new Date(item.updatedAt).toLocaleString(),
    }));

    return data;
  },

  async getById(id) {
    const response = await axios.get(
      `${process.env.REACT_APP_STRAPI_URL}/api/users/${id}`
    );
    const res = response.data;

    const _data = {
      id: res.id,
      username: res.username,
      email: res.email,
      confirmed: res.confirmed,
      blocked: res.blocked,
      avatarUrl: res.avatarUrl,
      phone: res.phone || "",
      firstName: res.firstName || "",
      lastName: res.lastName || "",
      age: res.age || "",
      is2fa: res.is2fa,
      isBioAuth: res.isBioAuth,
      isVerified: res.isVerified,
      isSubChatNotification: res.isSubChatNotification,
    };

    return _data;
  },

  async create(data, jwt) {
    const response = await axios.post(
      `${process.env.REACT_APP_STRAPI_URL}/api/users`,
      { data },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${jwt}`,
        },
      }
    );

    return response.data;
  },

  async update(id, data, jwt) {
    const response = await axios.put(
      `${process.env.REACT_APP_STRAPI_URL}/api/users/${id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${jwt}`,
        },
      }
    );

    return response.data;
  },

  async delete(id, jwt) {
    const response = await axios.delete(
      `${process.env.REACT_APP_STRAPI_URL}/api/users/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${jwt}`,
        },
      }
    );

    return response.data;
  },
};
