import { Box, Button, Typography } from "@mui/material";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CommissionActions from "./CommissionActions";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { CommissionService } from "../../api/commission.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Commissions = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const [Commission, setCommission] = useState([]);
  const [rowId, setRowId] = useState();

  useEffect(() => {
    const fetchCommissionData = async () => {
      const data = await CommissionService.getAll();
      setCommission(data);
    };

    fetchCommissionData();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      editable: true,
      cellClassName: "name-column--cell",
    },
    {
      field: "symbol",
      headerName: "Symbol",
      flex: 1,
      editable: true,
      //cellClassName: "name-column--cell",
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      editable: true,
      //cellClassName: "name-column--cell",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
      editable: true,
      //cellClassName: "name-column--cell",
    },

    {
      field: "amount",
      type: "number",
      headerName: "Amount",
      flex: 1,
      headerAlign: "left",
      align: "left",
      editable: true,
      cellClassName: "name-column--cell",
      renderCell: (params) => <Typography>{params.row.amount}</Typography>,
    },
    {
      field: "currencyFee",
      headerName: "Currency Fee",
      flex: 1,
      editable: true,
      cellClassName: "name-column--cell",
    },
    {
      field: "percent",
      type: "number",
      headerName: "Percent of amount",
      flex: 1,
      headerAlign: "left",
      align: "left",
      editable: true,
      cellClassName: "name-column--cell",
      renderCell: (params) => <Typography>{params.row.percent} %</Typography>,
    },
    {
      field: "isActive",
      headerName: "Active",
      type: "boolean",
      flex: 0.5,
      editable: true,
      //cellClassName: "name-column--cell",
    },
    {
      field: "updatedAt",
      headerName: "Updated at",
      flex: 1,
    },
    {
      field: "actions",
      width: 150,
      headerName: "Actions",
      type: "actions",
      renderCell: (params) => (
        <CommissionActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("COMMISSION")} subtitle={t("List of Commission")} />

        <Box>
          <Button
            onClick={() => navigate(`/commissions/create`)}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <AddOutlinedIcon sx={{ mr: "10px" }} />
            {t("Create Commission")}
          </Button>
        </Box>
      </Box>

      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            display: "flex",
            alignItems: "center",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          '.MuiDataGrid-booleanCell[data-value="true"]': {
            color: `${colors.greenAccent[500]} !important `,
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-container--top [role=row]": {
            backgroundColor: `${colors.blueAccent[700]} !important`,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={Commission}
          columns={columns}
          getRowId={(row) => row.id}
          onCellEditStart={(params) => setRowId(params.id)}
          slots={{
            toolbar: GridToolbar,
          }}
        />
      </Box>
    </Box>
  );
};

export default Commissions;
