import { useTheme } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { tokens } from "../../theme";
import { SupportService } from "../../api/support.service";
import { useAuth } from "../../providers/AuthProvider";
import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from "../../hooks/useAlert";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  useMediaQuery,
  Divider,
} from "@mui/material";
import CustomCheckbox from "../../components/CustomCheckbox";
import { SupportTiketService } from "../../api/supportTiket.service";
import { useTranslation } from "react-i18next";

const SupportFeedback = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const showAlert = useAlert();
  const { userInfo } = useAuth();
  const { id } = useParams();
  const [support, setSupport] = useState();
  const [managerMessage] = useState("");

  const handleFormSubmit = (values) => {
    // console.log("values:", values);

    const dataTiket = {
      supportId: values.id,
      message: values.managerMessage,
      manager: values.manager,
    };
    // console.log("tiket:", dataTiket);

    const dataFormik = {
      id: values.id,
      manager: values.manager,
      completed: values.completed,
      status: values.status,
    };
    // console.log("dataFormik:", dataFormik);

    createSupportTiket(dataTiket);
    updateSupport(dataFormik);
  };

  // create tiket
  const createSupportTiket = async (data) => {
    try {
      await SupportTiketService.create(data, userInfo.jwt);
      showAlert(t("Create support tiket successfully"), "success");
    } catch (error) {
      showAlert(t("Create support tiket error"), "error");
      console.log("Error create tiket", error);
    }
  };

  // update support
  const updateSupport = async (data) => {
    try {
      await SupportService.update(id, data, userInfo.jwt);
      showAlert(t("Edit support successfully"), "success");
      navigate("/supports");
    } catch (error) {
      showAlert(t("Edit support error"), "error");
      console.log("Error edit", error);
    }
  };

  useEffect(() => {
    const fetchSupportById = async (id) => {
      const data = await SupportService.getById(id);
      setSupport(data);
    };

    fetchSupportById(id);
  }, [id]);

  if (!support) return;

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={{
        id: support.id,
        themeMessage: support.themeMessage,
        userMessage: support.userMessage,
        manager: userInfo?.user.username,
        completed: support.completed || false,
        status: support.status,
        managerMessage: managerMessage,
      }}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              type="text"
              label={t("Id Support")}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.id}
              name="id"
              error={!!touched.id && !!errors.id}
              helperText={touched.id && errors.id}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              type="text"
              label={t("Theme Support")}
              color="secondary"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.themeMessage}
              name="title"
              error={!!touched.themeMessage && !!errors.themeMessage}
              helperText={touched.themeMessage && errors.themeMessage}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              type="text"
              color="secondary"
              label={t("Message Support")}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.userMessage}
              name="userMessage"
              error={!!touched.userMessage && !!errors.userMessage}
              helperText={touched.userMessage && errors.userMessage}
              sx={{ gridColumn: "span 4" }}
            />

            <Divider sx={{ gridColumn: "span 4" }}></Divider>

            <TextField
              fullWidth
              autoFocus
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              type="text"
              color="secondary"
              label={t("For Message")}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.managerMessage}
              name="managerMessage"
              placeholder={t("Enter your message to the client")}
              error={!!touched.managerMessage && !!errors.managerMessage}
              helperText={touched.managerMessage && errors.managerMessage}
              sx={{ gridColumn: "span 4" }}
            />

            <TextField
              fullWidth
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              color="secondary"
              type="text"
              label={t("Current Manager")}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.manager}
              name="manager"
              error={!!touched.manager && !!errors.manager}
              helperText={touched.manager && errors.manager}
              sx={{ gridColumn: "span 2" }}
            />

            <FormControl
              variant="filled"
              color="secondary"
              sx={{ gridColumn: "span 2" }}
            >
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                id="status-select"
                value={values.status}
                onChange={handleChange}
                label={t("Status")}
                name="status"
                color="secondary"
              >
                <MenuItem value="pending">{t("pending")}</MenuItem>
                <MenuItem value="processing">{t("processing")}</MenuItem>
                <MenuItem value="ready">{t("ready")}</MenuItem>
              </Select>
            </FormControl>

            <CustomCheckbox
              name="completed"
              label={t("Completed")}
              colors={colors}
              onChange={handleChange}
              checked={values?.completed}
            />
          </Box>
          <Box display="flex" justifyContent="end" mt="20px" gap={1}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => navigate(-1)}
            >
              {t("Go Back")}
            </Button>
            <Button type="submit" color="secondary" variant="contained">
            {t("Send Message")}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

const checkoutSchema = yup.object().shape({
  themeMessage: yup.string().required("required"),
  userMessage: yup.string().required("required"),
  managerMessage: yup.string().required("required"),
});

export default SupportFeedback;
