import { Box, Button, Typography } from "@mui/material";
import React from "react";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotAccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("NOT ACCESS")} subtitle={t("Not Access")} />
      </Box>

      <Box mt="40px">
        <Typography variant="h1">{t("Opps! ;)")} </Typography>
        <Typography variant="h1">{t("Sorry!")}</Typography>
        <Typography variant="h3">
          {t("You cannot access this page! Contact the site administrator")}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="end" mt="40px" gap={1}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => navigate(-1)}
        >
          {t("Go Back")}
        </Button>
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          onClick={() => navigate("/")}
        >
          {t("Home")}
        </Button>
      </Box>
    </Box>
  );
};

export default NotAccess;
