import React from "react";
import { useField, useFormikContext } from "formik";
import { FormControlLabel, Checkbox, FormHelperText } from "@mui/material";

const CustomCheckbox = ({ label, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });
  const { setFieldValue } = useFormikContext();

  const handleChange = (event) => {
    const { checked } = event.target;
    setFieldValue(props.name, checked);
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            {...field}
            checked={field.value}
            onChange={handleChange}
            sx={{
              color: `${props.colors?.greenAccent[800]} !important`,
              "&.Mui-checked": {
                color: `${props.colors?.greenAccent[600]} !important`,
              },
            }}
          />
        }
        label={label}
      />
      {meta.touched && meta.error ? (
        <FormHelperText error>{meta.error}</FormHelperText>
      ) : null}
    </div>
  );
};

export default CustomCheckbox;
