import { Box, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import UserActions from "./UserActions";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { UserService } from "../../api/user.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Users = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const [user, setUser] = useState();

  useEffect(() => {
    const fetchUserData = async () => {
      const data = await UserService.getAll();
      setUser(data);
    };

    fetchUserData();
  }, []);

  if (!user) return;
  

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "avatarUrl",
      headerName: "Avatar",
      flex: 0.5,
      renderCell: (params) => (
        <img
          alt="user"
          width="25px"
          height="25px"
          src={
            params.row?.avatarUrl
              ? `${process.env.REACT_APP_STRAPI_URL}${params.row.avatarUrl}`
              : `../../assets/user.png`
          }
          style={{ cursor: "pointer", borderRadius: "50%" }}
        />
      ),
    },
    {
      field: "username",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "confirmed",
      headerName: "Confirmed",
      flex: 1,
      type: "boolean",
    },
    {
      field: "isVerified",
      headerName: "Verified",
      flex: 1,
      type: "boolean",
    },
    {
      field: "blocked",
      headerName: "Blocked",
      flex: 1,
      type: "boolean",
    },
    {
      field: "is2fa",
      headerName: "2FA",
      flex: 1,
      type: "boolean",
    },
    {
      field: "isBioAuth",
      headerName: "Bio Auth",
      flex: 1,
      type: "boolean",
    },
    {
      field: "isSubChatNotification",
      headerName: "Sub Chat Notify",
      flex: 1,
      type: "boolean",
    },
    {
      field: "createdAt",
      headerName: "Created at",
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: "Updated at",
      flex: 1,
    },
    {
      field: "actions",
      minWidth: 120,
      headerName: "Actions",
      type: "actions",
      flex: 1,
      renderCell: (params) => <UserActions {...{ params }} />,
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("USERS")} subtitle={t("List of Users")} />

        <Box>
          <Button
            onClick={() => navigate(`/users/create`)}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <AddOutlinedIcon sx={{ mr: "10px" }} />
            {t("Create User")}
          </Button>
        </Box>
      </Box>

      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            display: "flex",
            alignItems: "center",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          '.MuiDataGrid-booleanCell[data-value="true"]': {
            color: `${colors.greenAccent[500]} !important `,
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-container--top [role=row]": {
            backgroundColor: `${colors.blueAccent[700]} !important`,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={user}
          columns={columns}
          slots={{
            toolbar: GridToolbar,
          }}
        />
      </Box>
    </Box>
  );
};

export default Users;
