import { Box, Button, Divider, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import CustomCheckbox from "../../components/CustomCheckbox";
import { useEffect, useState } from "react";
import { useAuth } from "../../providers/AuthProvider";
import { useNavigate, useParams } from "react-router-dom";
import { AccountService } from "../../api/account.service";
import { useAlert } from "../../hooks/useAlert";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const AccountEdit = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userInfo } = useAuth();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { id } = useParams();
  const showAlert = useAlert();
  const [account, setAccount] = useState();

  const handleFormSubmit = (values) => {
    console.log("values:", values);
    updateaccount(values);
  };

  // update
  const updateaccount = async (data) => {
    try {
      await AccountService.update(id, data, userInfo.jwt);
      showAlert(t("Edit account successfully"), "success");
      navigate("/accounts");
    } catch (error) {
      showAlert(t("Edit account error"), "error");
      console.log("Error edit", error);
    }
  };

  useEffect(() => {
    //get data Id
    const fetchaccountById = async (id) => {
      const data = await AccountService.getById(id);
      setAccount(data);
    };

    fetchaccountById(id);
  }, [id]);

  if (!account) return;

  return (
    <Box m="20px">
      <Header title={t("EDIT account")} subtitle={t("Edit account")} />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={{
          id: account.id,
          numberAccount: account.numberAccount,
          nameAccount: account.nameAccount,
          coin: account.coin,
          symbol: account.symbol,
          pathAccount: account.pathAccount,
          imgsrc: account.imgsrc,
          contractAddress: account.contractAddress,
          privateKey: account.privateKey,
          isActive: account.isActive,
        }}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              mt="40px"
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                type="text"
                label="Id"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.id}
                // value={account.id}
                // defaultValue={account.id}
                name="id"
                error={!!touched.id && !!errors.id}
                helperText={touched.id && errors.id}
                sx={{ gridColumn: "span 2" }}
              />
              <Divider sx={{ gridColumn: "span 4" }}></Divider>

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Number Account")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.numberAccount}
                name="numberAccount"
                error={!!touched.numberAccount && !!errors.numberAccount}
                helperText={touched.numberAccount && errors.numberAccount}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Name Account")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nameAccount}
                name="nameAccount"
                error={!!touched.nameAccount && !!errors.nameAccount}
                helperText={touched.nameAccount && errors.nameAccount}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Coin")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.coin}
                name="coin"
                error={!!touched.coin && !!errors.coin}
                helperText={touched.coin && errors.coin}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Symbol")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.symbol}
                name="symbol"
                error={!!touched.symbol && !!errors.symbol}
                helperText={touched.symbol && errors.symbol}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Path Account")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.pathAccount}
                name="pathAccount"
                error={!!touched.pathAccount && !!errors.pathAccount}
                helperText={touched.pathAccount && errors.pathAccount}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Img Src")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.imgsrc}
                name="imgsrc"
                error={!!touched.imgsrc && !!errors.imgsrc}
                helperText={touched.imgsrc && errors.imgsrc}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Contract Address")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.contractAddress}
                name="contractAddress"
                error={!!touched.contractAddress && !!errors.contractAddress}
                helperText={touched.contractAddress && errors.contractAddress}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Private Key")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.privateKey}
                name="privateKey"
                error={!!touched.privateKey && !!errors.privateKey}
                helperText={touched.privateKey && errors.privateKey}
                sx={{ gridColumn: "span 2" }}
              />

              {/* TODO checkbox */}

              <CustomCheckbox
                name="isActive"
                label={t("Is Active")}
                colors={colors}
                onChange={handleChange}
                checked={values?.isActive}
              />

              <Divider sx={{ gridColumn: "span 4" }}></Divider>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px" gap={1}>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => navigate(-1)}
              >
                {t("Go Back")}
              </Button>
              <Button type="submit" color="secondary" variant="contained">
                {t("Save")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  numberAccount: yup.string().required("required"),
  // nameAccount: yup.string().required("required"),
  coin: yup.string().required("required"),
  symbol: yup.string().required("required"),
  // pathAccount: yup.string().required("required"),
  // imgsrc: yup.string().required("required"),
  // contractAdress: yup.string().required("required"),
  // privateKey: yup.string().required("required"),
});

export default AccountEdit;
