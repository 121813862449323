import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import axios from "axios";
import { CoinContext } from "../../providers/CoinProvider";
import { useAlert } from "../../hooks/useAlert";
import { useTranslation } from "react-i18next";
// import { AES, enc } from "crypto-js";

const ConfirmTransaction = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { updateAccounts } = useContext(CoinContext);
  const showAlert = useAlert();
  const location = useLocation();
  const {
    accountFrom,
    accountTo,
    contractAddress,
    amount,
    cryptosymbol,
    txFee,
    txFeeApp,
    // encryptPk,
  } = location.state;
  const [pk, setPk] = useState("");
  const [open, setOpen] = useState(false);
  const [etherscanLink, setEtherscanLink] = useState("");
  const [isTransfer, setTransfer] = useState(false);

  // console.log("encryptPK", encryptPk);

  // // Encrypt PK
  // console.log("encryptPKStart", encryptPk);
  // const encryptPrivateKey = AES.encrypt(encryptPk, `${process.env.REACT_APP_PASSWORD}`).toString();
  // console.log("encryptPrivateKey:", encryptPrivateKey);

  // // DecryptPK
  //   const bytes = AES.decrypt(encryptPrivateKey, `${process.env.REACT_APP_PASSWORD}`);
  //   const decryptPrivateKey = bytes.toString(enc.Utf8);
  //   console.log("decryptPrivateKey:", decryptPrivateKey);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    transfer();
  };

  const transfer = async () => {
    const recipientAddress = accountTo;
    const privateKey = pk;

    // DecryptPK
    // const bytes = AES.decrypt(key, `${process.env.REACT_APP_PASSWORD}`);
    // privateKey = bytes.toString(enc.Utf8);

    console.log("start");

    let coin = "token";
    if (cryptosymbol === "ETH") {
      coin = "eth";
    } else if (cryptosymbol === "ETC") {
      coin = "etc";
    } else if (cryptosymbol === "BTC") {
      coin = "btc";
    }

    await axios
      .post(`${process.env.REACT_APP_BASE_URL_SERVICES}/api/send${coin}`, {
        recipientAddress,
        amount,
        privateKey,
        contractAddress,
      })
      .then((res) => {
        console.log(`Transfer ${coin}:`, res.data);
        const tx = res.data;

        if (tx && cryptosymbol === "BTC") {
          setEtherscanLink(
            `https://live.blockcypher.com/btc-testnet/tx/${tx.txHash}`
          );
        } else {
          setEtherscanLink(`https://sepolia.etherscan.io/tx/${tx.hash}`);
        }

        if (tx) {
          showAlert(
            `Transaction success: \nAmount: ${amount}\nWait for transaction confirmation...`,
            "success"
          );
          setTransfer(true);
        }

        updateAccounts();
        // navigate(-1);
      })
      .catch((e) => {
        console.log(`Error ${e}`);
        showAlert(`Transaction error: \nAmount: ${e}\n`, "error");
      });
  };

  // console.log("location", location);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("CONFIRM")} subtitle={t("Confirm action")} />
      </Box>

      <Card  sx={{ width: 500, background: colors.primary[400], marginTop: '40px' }}>
        <CardContent>
          <Typography variant="h5" component="div">
            {t("Account From:")}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {accountFrom}
          </Typography>
          <Typography variant="h5" component="div">
             {t("Account To:")}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {accountTo}
          </Typography>
          {contractAddress && (
            <>
              <Typography variant="h5" component="div">
                 {t("Contract Address:")}
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {contractAddress}
              </Typography>
            </>
          )}

          <Typography variant="h5" component="div">
             {t("Amount:")}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {amount}
          </Typography>
          <Typography variant="h5" component="div">
             {t("Symbol:")}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {cryptosymbol}
          </Typography>

          <Divider>Fee</Divider>

          <Typography variant="h5" component="div">
             {t("*Liquidity Provider Fee")} ~
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {txFee}
          </Typography>
          <Typography variant="h5" component="div">
             {t("*Application Fee")} ~
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {txFeeApp}
          </Typography>
          <Typography variant="h5" component="div" color="secondary">
             {t("Total Fee")} ~
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
           {Number(txFee) + Number(txFeeApp)} 
          </Typography>



        </CardContent>

        <Divider></Divider>

        <CardContent>
          <Stack direction="row" spacing={2}>
            <Typography variant="body2" sx={{ color: colors.blueAccent[500] }}>
              {t("Private key is required to send a transaction")}
            </Typography>
            <Button
              color="secondary"
              variant="outlined"
              onClick={handleClickOpen}
            >
             {t("Download")}
            </Button>

            {/* Dialog */}
            <Dialog
              open={open}
              onClose={handleClose}
              PaperProps={{
                component: "form",
                onSubmit: (event) => {
                  event.preventDefault();
                  const formData = new FormData(event.currentTarget);
                  const formJson = Object.fromEntries(formData.entries());
                  const newPk = formJson.pk;
                  console.log("pk:", newPk);
                  setPk(newPk);
                  handleClose();
                },
              }}
            >
              <DialogTitle>{t("ATTENTION!")}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t("Private key is required to send a transaction. Enter in the following field")}
                </DialogContentText>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="name"
                  name="pk"
                  label={t("Private Key")}                  
                  type="text"
                  fullWidth
                  variant="standard"
                  color="secondary"
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={handleClose}
                >
                  {t("Cancel")}
                </Button>
                <Button type="submit" color="secondary" variant="contained">
                 {t("Save")}
                </Button>
              </DialogActions>
            </Dialog>
          </Stack>
          <Typography variant="body2">{t("Current Private Key:")}</Typography>
          <Typography variant="body2" color="text.secondary">
            {pk ? pk : t("No data")}
          </Typography>
        </CardContent>

        <Divider></Divider>

        <CardActions>
          <Box display="flex" justifyContent="end" mt="10px" mb="10px" gap={2}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => navigate(-1)}
            >
              {t("Go Back")}
            </Button>
            <Button
              type="submit"
              disabled={isTransfer ? true : false}
              color="secondary"
              variant="contained"
              onClick={handleClick}
            >
              {t("Confirm and Send")}
            </Button>
          </Box>
        </CardActions>
      </Card>

      {etherscanLink && (
        <Box mt={2}>
          <Typography>{t("Recent Transactions:")}</Typography>
          <Link
            href={etherscanLink}
            target="_blank"
            color={colors.greenAccent[500]}
          >
            {etherscanLink}
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default ConfirmTransaction;
