import process from "process";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./providers/AuthProvider";
import { AlertProvider } from "./hooks/useAlert";
import { CoinProvider } from "./providers/CoinProvider";
import { ChatProvider } from "./providers/ChatProvider";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/config";

window.global = window;
window.process = process;
window.Buffer = [];

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <AlertProvider>
        <AuthProvider>
          <CoinProvider>
            <ChatProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </ChatProvider>
          </CoinProvider>
        </AuthProvider>
      </AlertProvider>
    </I18nextProvider>
  </React.StrictMode>
);
