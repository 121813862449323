import { Logout } from "@mui/icons-material";
import { Box, ListItemIcon, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ChatContext } from "../../providers/ChatProvider";

const LogoutButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSelectedConversation } = useContext(ChatContext);

  const handleLogout = () => {
    setSelectedConversation(null);
    navigate(`/`);
  };

  return (
    <>
      <Box
        display="flex"
        sx={{ position: "absolute", bottom: 15, cursor: "pointer" }}
      >
        <ListItemIcon onClick={handleLogout}>
          <Logout fontSize="small" />
          <Typography ml={1}>{t("Logout")}</Typography>
        </ListItemIcon>
      </Box>
    </>
  );
};

export default LogoutButton;
