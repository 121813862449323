import { useState } from "react";
import { Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { useAuth } from "./providers/AuthProvider";
import Topbar from "./screens/global/Topbar";
import Sidebar from "./screens/global/Sidebar";
import IdleTimer from "./screens/global/IdleTimer";
import SignIn from "./screens/signin/SignIn";
import Dashboard from "./screens/dashboard/Dashboard";
import Team from "./screens/team/Team";
import Profile from "./screens/users/Profile";
import Invoices from "./screens/invoices/Invoices";
import Assets from "./screens/assets/Assets";
import Notifications from "./screens/notifications/Notifications";
import NotificationCreate from "./screens/notifications/NotificationCreate";
import NotificationEdit from "./screens/notifications/NotificationEdit";
import NotificationView from "./screens/notifications/NotificationView";
import Coins from "./screens/coins/Coins";
import OrderCoins from "./screens/coins/OrderCoins";
import Contacts from "./screens/contacts/Contacts";
import Credit from "./screens/services/Credit";
import Deposit from "./screens/services/Deposit";
import CurrencyRates from "./screens/exchange/CurrencyRates";
import OrderExchange from "./screens/exchange/OrderExchange";
import FAQ from "./screens/faq/FAQ";
import NotFound from "./screens/404/NotFound";
import Support from "./screens/support/Support";
import SupportView from "./screens/support/SupportView";
import Users from "./screens/users/Users";
import UserCreate from "./screens/users/UserCreate";
import UserView from "./screens/users/UserView";
import UserEdit from "./screens/users/UserEdit";
import SupportCreate from "./screens/support/SupportCreate";
import SupportEdit from "./screens/support/SupportEdit";
import CurrencyRateCreate from "./screens/exchange/CurrencyRateCreate";
import Accounts from "./screens/accounts/Accounts";
import AccountView from "./screens/accounts/AccountView";
import ConfirmTransaction from "./screens/accounts/ConfirmTransaction";
import AccountHistotyDetail from "./screens/accounts/AccountHistotyDetail";
import AccountCreate from "./screens/accounts/AccountCreate";
import AccountEdit from "./screens/accounts/AccountEdit";
import Commissions from "./screens/settings/Commissions";
import CoinRates from "./screens/settings/CoinRates";
import CoinRateCreate from "./screens/settings/CoinRateCreate";
import CommissionCreate from "./screens/settings/CommissionCreate";
import NotAccess from "./screens/404/NotAccess";
import Chat from "./screens/chat/Chat";
import VideoChat from "./screens/chat/VideoChat";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const { userToken, userInfo } = useAuth();
  const location = useLocation();

  const shouldHideSidebarAndTopbar = (pathname) => {
    return pathname === "/videochat";
  };

  const ProtectedRoute = ({ isAllowed, redirectPath = "/", children }) => {
    if (!isAllowed) {
      return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {userToken ? (
          <div className="app">
            <IdleTimer />

            {!shouldHideSidebarAndTopbar(location.pathname) && (
              <Sidebar isSidebar={isSidebar} />
            )}

            <main className="content">
              {!shouldHideSidebarAndTopbar(location.pathname) && (
                <Topbar setIsSidebar={setIsSidebar} />
              )}

              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/team" element={<Team />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/users" element={<Users />} />
                <Route path="/users/create" element={<UserCreate />} />
                <Route path="/users/:id" element={<UserView />} />
                <Route path="/users/:id/edit" element={<UserEdit />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/invoices" element={<Invoices />} />
                <Route path="/assets" element={<Assets />} />
                <Route path="/notifications" element={<Notifications />} />
                <Route
                  path="/notifications/create"
                  element={<NotificationCreate />}
                />
                <Route
                  path="/notifications/:id"
                  element={<NotificationView />}
                />
                <Route
                  path="/notifications/:id/edit"
                  element={<NotificationEdit />}
                />
                <Route path="/coins" element={<Coins />} />
                <Route path="/order-coin" element={<OrderCoins />} />
                <Route path="/credit" element={<Credit />} />
                <Route path="/deposit" element={<Deposit />} />
                <Route path="/currency-rates" element={<CurrencyRates />} />
                <Route
                  path="/currency-rates/create"
                  element={<CurrencyRateCreate />}
                />
                <Route path="/order-exchange" element={<OrderExchange />} />
                <Route path="/supports" element={<Support />} />
                <Route path="/supports/create" element={<SupportCreate />} />
                <Route path="/supports/:id" element={<SupportView />} />
                <Route path="/supports/:id/edit" element={<SupportEdit />} />

                <Route path="/faq" element={<FAQ />} />
                <Route path="/chat" element={<Chat />} />
                <Route path="/videochat" element={<VideoChat />} />
                <Route path="/access" element={<NotAccess />} />
                <Route path="*" element={<NotFound />} />

                {/* PROTECTED ROUTES */}

                {/* ACCOUNTANT */}
                <Route
                  element={
                    <ProtectedRoute
                      redirectPath="/access"
                      isAllowed={
                        (!!userInfo &&
                          userInfo?.user?.accessLevel?.roles?.includes(
                            "accountant"
                          )) ||
                        userInfo?.user?.accessLevel?.roles?.includes("admin")
                      }
                    />
                  }
                >
                  <Route path="/accounts" element={<Accounts />} />
                  <Route path="/accounts/create" element={<AccountCreate />} />
                  <Route path="/accounts/:id" element={<AccountView />} />
                  <Route path="/accounts/:id/edit" element={<AccountEdit />} />
                  <Route
                    path="/confirm-transaction"
                    element={<ConfirmTransaction />}
                  />
                  <Route
                    path="/history-details"
                    element={<AccountHistotyDetail />}
                  />
                </Route>

                {/* ADMIN */}
                <Route
                  element={
                    <ProtectedRoute
                      redirectPath="/access"
                      isAllowed={
                        !!userInfo &&
                        userInfo?.user?.accessLevel?.roles?.includes("admin")
                      }
                    />
                  }
                >
                  <Route path="/commissions" element={<Commissions />} />
                  <Route
                    path="/commissions/create"
                    element={<CommissionCreate />}
                  />
                  <Route path="/coin-rates" element={<CoinRates />} />
                  <Route
                    path="/coin-rates/create"
                    element={<CoinRateCreate />}
                  />
                </Route>
              </Routes>
            </main>
          </div>
        ) : (
          <SignIn />
        )}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
