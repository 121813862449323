import axios from "axios";

export const CoinRateService = {
  async getAll() {
    const response = await axios.get(
      `${process.env.REACT_APP_STRAPI_URL}/api/coin-rates`
    );

    const res = response.data.data;
    // console.log(res);

    const data = res.map((item) => ({
      id: item.id,
      symbol: item.attributes.symbol,
      price: item.attributes.price,      
      isActive: item.attributes.isActive,      
      createdAt: new Date(item.attributes.createdAt).toLocaleString(),
      updatedAt: new Date(item.attributes.updatedAt).toLocaleString(),
      publishedAt: new Date(item.attributes.publishedAt).toLocaleString(),
    }));

    return data;
  },

  async getById(id) {
    const response = await axios.get(
      `${process.env.REACT_APP_STRAPI_URL}/api/coin-rates/${id}`
    );
    const res = response.data.data;

    const data = {     
      id: res.id,
      symbol: res.attributes.symbol,
      price: res.attributes.price,      
      isActive: res.attributes.isActive, 
    };

    return data;
  },

  async create(data, jwt) {
    const response = await axios.post(
      `${process.env.REACT_APP_STRAPI_URL}/api/coin-rates`,
      { data },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${jwt}`,
        },
      }
    );

    return response.data;
  },

  async update(id, data, jwt) {   
    const response = await axios.put(
      `${process.env.REACT_APP_STRAPI_URL}/api/coin-rates/${id}`,
      { data },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${jwt}`,
        },
      }
    );

    return response.data;
  },

  async delete(id, jwt) {
    const response = await axios.delete(
      `${process.env.REACT_APP_STRAPI_URL}/api/coin-rates/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${jwt}`,
        },
      }
    );

    return response.data;
  },
};
