import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useAlert } from "../../hooks/useAlert";
import { useAuth } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { CoinRateService } from "../../api/coinRate.service";
import { useTranslation } from "react-i18next";

const CoinRateCreate = () => {
  const { t } = useTranslation();
  const { userInfo } = useAuth();
  const navigate = useNavigate();
  const showAlert = useAlert();

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = (values) => {
    console.log(values);
    createNewcoinRate(values);
  };

  // add new coinRate
  const createNewcoinRate = async (data) => {
    try {
      await CoinRateService.create(data, userInfo.jwt);
      showAlert(t("Create coin Rate successfully"), "success");
      navigate("/coin-rates");
    } catch (error) {
      showAlert(t("Create coin Rate error"), "error");
      console.log("Error create", error);
    }
  };

  return (
    <Box m="20px">
      <Header title={t("CREATE Coin Rate")} subtitle={t("Create a New Coin Rate")} />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              mt="40px"
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Symbol")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.symbol}
                name="symbol"
                error={!!touched.symbol && !!errors.symbol}
                helperText={touched.symbol && errors.symbol}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Price")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.price}
                name="price"
                error={!!touched.price && !!errors.price}
                helperText={touched.price && errors.price}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>

            <Box display="flex" justifyContent="end" mt="20px" gap={1}>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => navigate(-1)}
              >
                {t("Go Back")}
              </Button>
              <Button type="submit" color="secondary" variant="contained">
                {t("Create New Coin Rate")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  symbol: yup.string().required("required"),
  price: yup.string().required("required"),
  // isActive: yup.string().required("required"),
});

const initialValues = {
  symbol: "",
  price: "",
};

export default CoinRateCreate;
