import { Box, IconButton, Tooltip } from "@mui/material";
import { Delete, Edit, Preview } from "@mui/icons-material";
import { useAuth } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../hooks/useAlert";
import { UserService } from "../../api/user.service";
import { useTranslation } from "react-i18next";

const UserActions = ({ params }) => {
  const { t } = useTranslation();
  const { userInfo } = useAuth();
  const navigate = useNavigate();
  const showAlert = useAlert();

  // refresh
  const refreshPage = () => {
    window.location.reload(true);
  };

  // delete User
  const deleteUser = async (id) => {
    if (!id) return;

    try {
      await UserService.delete(id, userInfo.jwt);
      showAlert(t("Delete User successfully"), "success");
      refreshPage();
    } catch (error) {
      console.log("Error delete", error);
    }
  };

  return (
    <>
      <Box>
        <Tooltip title={t("View User")}>
          <IconButton onClick={() => navigate(`/users/${params.id}`)}>
            <Preview />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("Edit this User")}>
          <IconButton onClick={() => navigate(`/users/${params.id}/edit`)}>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("Delete this User")}>
          <IconButton onClick={() => deleteUser(params.id)}>
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
};

export default UserActions;
