import { Box, Typography } from "@mui/material";
import Header from "../../components/Header";
import { useTranslation } from "react-i18next";


const OrderExchange = () => { 
  const { t } = useTranslation();
  
  return (
    <Box m="20px">
      <Header
        title={t("ORDER EXCHANGE")}
        subtitle={t("List of Order Exchange")}
      />
      
      <Typography variant="h3" mt="30px">
        {t("A section of the site is under development...")}
      </Typography>
    </Box>
  );
};

export default OrderExchange;





