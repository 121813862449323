import React, { useCallback, useContext, useEffect } from "react";
import { styled } from "@mui/system";
import { AppBar, Box, Container, IconButton, Tooltip, Typography } from "@mui/material";
import { Logout } from "@mui/icons-material";

import VideoPlayer from "../../components/videochat/VideoPlayer";
import Options from "../../components/videochat/Options";
import Notifications from "../../components/videochat/Notifications";
import { ChatContext } from "../../providers/ChatProvider";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MessageContainer from "../../components/messages/MessageContainer";
import SidebarChat from "../../components/sidebarchat/SidebarChat";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  borderRadius: 15,
  margin: "30px 100px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  width: "600px",
  border: `2px solid ${theme.palette.divider}`,

  [theme.breakpoints.down("xs")]: {
    width: "90%",
  },
}));

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
});

const VideoChat = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { myVideo, stream, setStream, leaveCall } = useContext(ChatContext);

  const stopStream = useCallback(async () => {
    if (stream) {
      console.log("Stopping stream:", stream);

      // Ensure all tracks are stopped
      stream.getTracks().forEach((track) => {
        if (track.readyState === "live") {
          try {
            track.stop(); // Try to stop each track (video/audio)
            console.log("Track stopped:", track);
          } catch (error) {
            console.error("Error stopping track:", error);
          }
        }
      });

      // Remove stream from video element
      if (myVideo.current) {
        myVideo.current.srcObject = null;
        console.log("Stream removed from video element.");
      }

      // Ensure that the stream object is cleared
      setStream(null);
      console.log("Stream set to null.");
    } else {
      console.log("No stream to stop.");
    }

    // Add a short delay to ensure that all resources are cleaned up
    await new Promise((resolve) => setTimeout(resolve, 100));
  }, [stream, myVideo, setStream]);

  useEffect(() => {
    const getUserMedia = async () => {
      try {
        // Request access to video and audio
        const currentStream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });

        console.log("Stream obtained:", currentStream);

        // Set the stream in state
        setStream(currentStream);

        // Assign stream to video element
        if (myVideo.current) {
          myVideo.current.srcObject = currentStream;
          console.log("Stream assigned to video element.");
        }
      } catch (error) {
        console.error("Error accessing media devices:", error);
      }
    };

    getUserMedia();

    // Cleanup: Stop stream when component unmounts
    return () => {
      console.log("Unmounting: stopping stream");
      stopStream(); // Stops camera and mic
    };

    // Note: Only run this effect when the component mounts (empty dependencies).
  }, []);

  // Only run this effect when the video element is first available (and stream exists)
  useEffect(() => {
    if (stream && myVideo.current && !myVideo.current.srcObject) {
      // Assign stream only if it's not already assigned
      myVideo.current.srcObject = stream;
      console.log("Stream reassigned to video element.");
    }
  }, [myVideo]); // Only watch for changes in the video element reference

  const handleExit = () => {
    console.log("Exit button clicked.");
    stopStream(); // Stops the media stream
    leaveCall(); // Clean up peer connection if any

    // Добавление задержки перед навигацией или перезагрузкой
    setTimeout(() => {
      navigate("/chat", {});
      window.location.reload();
    }, 1000); // Задержка 1 секунда
  };

  return (
    <Wrapper>
      <StyledAppBar position="static" color="inherit">
        <Typography variant="h2" align="center" color="secondary">
          {t("VIDEO CHAT")}
        </Typography>

        <Tooltip title={t("Exit")}>
          <IconButton onClick={handleExit}>
            <Logout fontSize="small" />
          </IconButton>
        </Tooltip>
      </StyledAppBar>
      <VideoPlayer />
      <Options>
        <Notifications />
      </Options>
      
      <Container sx={{ minWidth: "750px" }}>
        <Box
          m="40px"
          display="flex"
          sx={{ bgcolor: colors.primary[400], height: "60vh" }}
        >
          <SidebarChat />
          <MessageContainer />
        </Box>
      </Container>


    </Wrapper>
  );
};

export default VideoChat;
