import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import TopUserMenu from "./TopUserMenu";
import TopNotificationMenu from "./TopNotificationMenu";
import TopSupportMenu from "./TopSupportMenu";
import { NotificationService } from "../../api/notification.service";
import { SupportService } from "../../api/support.service";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../providers/AuthProvider";

const Topbar = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { userInfo } = useAuth();
  const [anchorUserMenu, setAnchorUserMenu] = useState(null);
  const [anchorNotificationMenu, setAnchorNotificationMenu] = useState(null);
  const [badgeNotification, setBadgeNotification] = useState(null);
  const [isShowBadgeNotification, setShowBadgeNotification] = useState(true);
  const [notification, setNotification] = useState([]);

  const [anchorSupportMenu, setAnchorSupportMenu] = useState(null);
  const [badgeSupport, setBadgeSupport] = useState(null);
  const [isShowBadgeSupport, setShowBadgeSupport] = useState(true);
  const [support, setSupport] = useState([]);

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
    localStorage.setItem("i18nextLng", e.target.value);
  }

  const fetchNotification = async () => {
    try {
      const data = await NotificationService.getAll();
      setNotification(data);

      if (data.length > 0) {
        setBadgeNotification(data.length);
      } else {
        setBadgeNotification("");
      }
    } catch (error) {
      console.log("error Notification", error);
    }
  };

  const fetchSupport = async () => {
    try {
      const data = await SupportService.getAll();
      setSupport(data);

      if (data.length > 0) {
        setBadgeSupport(data.length);
      } else {
        setBadgeSupport("");
      }
    } catch (error) {
      console.log("error Support", error);
    }
  };

  useEffect(() => {
    if (!userInfo) return;

    const interval = setInterval(() => {
      fetchNotification();
      fetchSupport();
    }, 20000);
    return () => clearInterval(interval);
  }, [userInfo]);

  if (!notification) return;
  if (!support) return;

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase
          sx={{ ml: 2, flex: 1 }}
          placeholder={t("Search")}
          id="search"
        />
        <IconButton type="button">
          <SearchIcon />
        </IconButton>
      </Box>

      {/* LANG */}
      <ButtonGroup variant="text" aria-label="Basic button group">
        <Button color="secondary" onClick={changeLanguage} value="en">
          EN
        </Button>
        <Button color="secondary" onClick={changeLanguage} value="ru">
          RU
        </Button>
        <Button color="secondary" onClick={changeLanguage} value="ua">
          UA
        </Button>
      </ButtonGroup>

      {/* ICONS */}
      <Box display="flex">
        <Tooltip title={t("Mode theme")}>
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
        </Tooltip>

        <Tooltip title={t("Open Supports")}>
          <Badge
            badgeContent={isShowBadgeSupport ? badgeSupport : null}
            color="error"
            overlap="circular"
          >
            <IconButton onClick={(e) => setAnchorSupportMenu(e.currentTarget)}>
              <EmailOutlinedIcon />
            </IconButton>
          </Badge>
        </Tooltip>

        {support.length !== 0 && (
          <TopSupportMenu
            {...{
              anchorSupportMenu,
              setAnchorSupportMenu,
              support,
              setShowBadgeSupport,
            }}
          />
        )}

        <Tooltip title={t("Open Notifications")}>
          <Badge
            badgeContent={isShowBadgeNotification ? badgeNotification : null}
            color="error"
            overlap="circular"
          >
            <IconButton
              onClick={(e) => setAnchorNotificationMenu(e.currentTarget)}
            >
              <NotificationsOutlinedIcon />
            </IconButton>
          </Badge>
        </Tooltip>

        {notification.length !== 0 && (
          <TopNotificationMenu
            {...{
              anchorNotificationMenu,
              setAnchorNotificationMenu,
              notification,
              setShowBadgeNotification,
            }}
          />
        )}

        <Tooltip title={t("Open User Menu")}>
          <IconButton onClick={(e) => setAnchorUserMenu(e.currentTarget)}>
            <PersonOutlinedIcon />
          </IconButton>
        </Tooltip>
        <TopUserMenu {...{ anchorUserMenu, setAnchorUserMenu }} />
      </Box>
    </Box>
  );
};

export default Topbar;
