import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useAlert } from "../../hooks/useAlert";
import { useContext } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { SupportService } from "../../api/support.service";
import { useTranslation } from "react-i18next";

const SupportCreate = () => {
  const { t } = useTranslation();
  const { userInfo } = useContext(AuthContext);
  const navigate = useNavigate();
  const showAlert = useAlert();

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = (values) => {
    console.log(values);
    createNewSupport(values);
  };

  // add new support
  const createNewSupport = async (values) => {
    const data = {
      userName: userInfo.user.username,
      userEmail: userInfo.user.email,
      ...values,
    };

    try {
      await SupportService.create(data, userInfo.jwt);
      showAlert(t("Create support successfully"), "success");
      navigate("/supports");
    } catch (error) {
      showAlert(t("Create support error"), "error");
      console.log("Error create", error);
    }
  };

  return (
    <Box m="20px">
      <Header title={t("CREATE support")} subtitle={t("Create a New support")} />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              mt="40px"
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Theme")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.themeMessage}
                name="themeMessage"
                error={!!touched.themeMessage && !!errors.themeMessage}
                helperText={touched.themeMessage && errors.themeMessage}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                multiline
                maxRows={4}
                variant="filled"
                type="text"
                label={t("Message")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.userMessage}
                name="userMessage"
                error={!!touched.userMessage && !!errors.userMessage}
                helperText={touched.userMessage && errors.userMessage}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px" gap={1}>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => navigate(-1)}
              >
                {t("Go Back")}
              </Button>
              <Button type="submit" color="secondary" variant="contained">
                {t("Create New Support")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  themeMessage: yup.string().required("required"),
  userMessage: yup.string().required("required"),
});

const initialValues = {
  themeMessage: "",
  userMessage: "",
};

export default SupportCreate;
