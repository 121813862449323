import { useContext, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
// import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CurrencyBitcoinOutlinedIcon from "@mui/icons-material/CurrencyBitcoinOutlined";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
// import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
// import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import NotificationAddOutlinedIcon from "@mui/icons-material/NotificationAddOutlined";
import PriceChangeOutlinedIcon from "@mui/icons-material/PriceChangeOutlined";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import InterpreterModeOutlinedIcon from "@mui/icons-material/InterpreterModeOutlined";
import { useAuth } from "../../providers/AuthProvider";
import { useTranslation } from "react-i18next";
// import AvatarWithStatus from "../avatar/AvatarWithStatus";
import AvatarWithStatus from "../../components/avatar/AvatarWithStatus";
import { ChatContext } from "../../providers/ChatProvider";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const { userInfo, userAvatar } = useAuth();
  const { onlineUsers } = useContext(ChatContext);

  const isOnline = onlineUsers.some((user) => userInfo?.user.id);

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  {t("ADMIN PANEL")}
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box
              mb="25px"
              display="flex"
              flexDirection="row"
              sx={{
                m: "0px 30px 20px 30px",
                borderRadius: 1,
                background: colors.greenAccent[700],
              }}
            >
              <Box display="flex" alignItems="center" m={1}>                

                <AvatarWithStatus
                  alt={userInfo?.user.username}
                  src={ userAvatar ? `${process.env.REACT_APP_STRAPI_URL}${userAvatar}` : null }
                  isOnline={isOnline}
                />

                {/* {userAvatar === "" ? (
                  <img
                    alt="profile-user"
                    width="50px"
                    height="50px"
                    src={`../../assets/user.png`}
                    style={{ cursor: "pointer", borderRadius: "50%" }}
                  />
                ) : (
                  <img
                    alt="profile-user"
                    width="50px"
                    height="50px"
                    src={`${process.env.REACT_APP_STRAPI_URL}${userAvatar}`}
                    style={{ cursor: "pointer", borderRadius: "50%" }}
                  />
                )} */}
              </Box>

              <Box alignItems="center">
                <Typography
                  variant="h4"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "5px 0 0 0" }}
                >
                  {userInfo?.user.username}
                </Typography>
                <Typography
                  variant="h5"
                  color={colors.greenAccent[400]}
                  mr="5px"
                >
                  {!!userInfo &&
                    userInfo?.user?.accessLevel?.roles?.includes("admin") &&
                    t("Administrator")}
                  {!!userInfo &&
                    userInfo?.user?.accessLevel?.roles?.includes(
                      "accountant"
                    ) &&
                    t("Accountant")}
                  {!!userInfo &&
                    userInfo?.user?.accessLevel?.roles?.includes("manager") &&
                    t("Manager")}
                  {!!userInfo &&
                    userInfo?.user?.accessLevel?.roles?.includes("dealer") &&
                    t("Dealer")}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title={t("Dashboard")}
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              {t("DATA")}
            </Typography>
            <Item
              title={t("Manage Team")}
              to="/team"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={t("Accounts APP")}
              to="/accounts"
              icon={<AccountBalanceWalletOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={t("Users Information")}
              to="/users"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Item
              title={t("Contacts Information")}
              to="/contacts"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            {/* <Item
              title={t("Invoices Balances")}
              to="/invoices"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Item
              title={t("Assets")}
              to="/assets"
              icon={<InsightsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={t("Notifications")}
              to="/notifications"
              icon={<NotificationAddOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              {t("COINS")}
            </Typography>
            <Item
              title={t("Coin Markets")}
              to="/coins"
              icon={<CurrencyBitcoinOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={t("Orders Coin")}
              to="/order-coin"
              icon={<LibraryBooksOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              SERVICES
            </Typography>
            <Item
              title={t("Credit")}
              to="/credit"
              icon={<BusinessCenterOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={t("Deposit")}
              to="/deposit"
              icon={<HandshakeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              {t("EXCHANGE")}
            </Typography>
            <Item
              title={t("Сurrency Rates")}
              to="/currency-rates"
              icon={<AttachMoneyOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={t("Orders Exchange")}
              to="/order-exchange"
              icon={<CurrencyExchangeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              {t("SETTINGS APP")}
            </Typography>

            <Item
              title={t("Commisions App")}
              to="/commissions"
              icon={<PriceChangeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title={t("Coin Rates App")}
              to="/coin-rates"
              icon={<AppRegistrationOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              {t("PAGES")}
            </Typography>
            <Item
              title={t("Supports message")}
              to="/supports"
              icon={<ChatOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Item
              title={t("Profile Form")}
              to="/form"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Item
              title={t("FAQ Page")}
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={t("Chat")}
              to="/chat"
              icon={<ForumOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={t("Meeting")}
              to="/intro-room"
              icon={<InterpreterModeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
 