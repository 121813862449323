import { Avatar, Box, Button, Grid, Stack, Typography } from "@mui/material";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useContext, useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AccountActions from "./AccountActions";
import { AccountService } from "../../api/account.service";
import CircleIcon from "@mui/icons-material/Circle";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import { CoinContext } from "../../providers/CoinProvider";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Accounts = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState([]);
  const { totalBalance, updateAccounts } = useContext(CoinContext);

  useEffect(() => {
    fetchAccountData();
    updateAccounts();
  }, []);

  const fetchAccountData = async () => {
    const data = await AccountService.getAll();
    // console.log(data);
    setAccounts(data);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "imgsrc",
      headerName: "Img",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
      renderCell: (params) =>
        params.row.imgsrc ? (
          <img alt="coin" width="25px" height="25px" src={params.row.imgsrc} />
        ) : (
          <Avatar sx={{ width: 25, height: 25 }}> </Avatar>
        ),
    },
    {
      field: "numberAccount",
      headerName: "Number Account",
      flex: 2.5,
      cellClassName: "name-column--cell",
    },
    {
      field: "nameAccount",
      headerName: "Name Account",
      flex: 1,
      // cellClassName: "name-column--cell",
    },
    {
      field: "coin",
      headerName: "Coin",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "symbol",
      headerName: "Symbol",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "pathAccount",
      headerName: "Path account",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "contractAddress",
      headerName: "Contract address",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "createdAt",
      headerName: "Created at",
      flex: 1,
    },
    {
      field: "isActive",
      minWidth: 100,
      headerName: "is Active",
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: ({ row: { isActive } }) => {
        return (
          <>
            <CircleIcon
              sx={{
                fontSize: 13,
                color: isActive
                  ? colors.greenAccent[600]
                  : colors.redAccent[600],
              }}
            />
            {isActive ? (
              <Typography sx={{ ml: 1, fontSize: 12 }}>{t("Active")}</Typography>
            ) : (
              <Typography sx={{ ml: 1, fontSize: 12 }}>{t("No Active")}</Typography>
            )}
          </>
        );
      },
    },

    {
      field: "actions",
      minWidth: 120,
      headerName: "Actions",
      type: "actions",
      flex: 1,
      renderCell: (params) => <AccountActions {...{ params }} />,
    },
  ];

  return (
    <Box m="20px">
      {/* GRID */}

      <Grid container spacing={5} justifyContent="space-between">
        <Grid item>
          <Header title={t("ACCOUNTS")} subtitle={t("List of Accounts")} />
        </Grid>
        <Grid item>
          <Box>
            <Button
              sx={{
                backgroundColor: colors.primary[400],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "12px 15px",
                minWidth: "225px",
              }}
            >
              <AccountBalanceOutlinedIcon sx={{ mr: "10px" }} />

              <Stack direction="row">
                <Typography sx={{ mr: "10px", fontWeight: "800" }}>
                  {t("Total Balance:")} {" "}
                </Typography>
                <Typography
                  sx={{ fontWeight: "800", color: colors.greenAccent[500] }}
                >
                  {totalBalance && Number(totalBalance).toFixed(2)} $
                </Typography>
              </Stack>
            </Button>
          </Box>
        </Grid>
        <Grid item>
          <Box>
            <Button
              sx={{
                backgroundColor: colors.primary[400],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "12px 15px",
                minWidth: "225px",
              }}
            >
              <HubOutlinedIcon sx={{ mr: "10px" }} />

              <Stack direction="row">
                <Typography sx={{ mr: "10px", fontWeight: "800" }}>
                  Net:{" "}
                </Typography>
                {process.env.NODE_ENV === "development" ? (
                  <Typography
                    sx={{ fontWeight: "800", color: colors.greenAccent[500] }}
                  >
                    {t("Sepolia Testnet")}
                  </Typography>
                ) : (
                  <Typography
                    sx={{ fontWeight: "800", color: colors.greenAccent[500] }}
                  >
                    {t("Mainnet")}
                  </Typography>
                )}
              </Stack>
            </Button>
          </Box>
        </Grid>
        <Grid item>
          <Box>
            <Button
              onClick={() => navigate(`/accounts/create`)}
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 15px",
                minWidth: "225px",
                marginBottom: "20px"
              }}
            >
              <AddOutlinedIcon sx={{ mr: "10px" }} />
              {t("Create Account")}
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            display: "flex",
            alignItems: "center",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-container--top [role=row]": {
            backgroundColor: `${colors.blueAccent[700]} !important`,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={accounts}
          columns={columns}
          slots={{
            toolbar: GridToolbar,
          }}
        />
      </Box>
    </Box>
  );
};

export default Accounts;
