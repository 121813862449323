import { Box, Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useTranslation } from "react-i18next";

const Coins = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [coinPrice, setCoinPrice] = useState([]);

  useEffect(() => {
    fetchCoinPriceData();
  }, []);

  const fetchCoinPriceData = async () => {
    try {
      const json = await fetch(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false&locale=en"
      );
      const res = await json.json();
      setCoinPrice(res);
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  if (!coinPrice) return;

  return (
    <Box m="20px">
      <Header title={t("COINS MARKET")} subtitle={t("List of Coins Market")} />

      {/* GRID */}
      <Grid container spacing={2} mt="20px">
        {coinPrice.map((curr, index) => (
          <Grid key={index} item>
            <Box backgroundColor={colors.primary[400]} minWidth="200px" p={2}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <img
                  alt="coin"
                  width="45px"
                  height="45px"
                  src={coinPrice ? curr.image : null}
                />
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  sx={{
                    color:
                      Number(curr.price_change_percentage_24h) > 0
                        ? colors.greenAccent[400]
                        : colors.redAccent[400],
                  }}
                >
                  {Number(curr.current_price).toFixed(2)} $
                </Typography>
              </Box>

              <Box
                sx={{
                  marginTop: 1,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  maxWidth="150px"
                  sx={{
                    color: colors.grey[100],
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {curr.name}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6" sx={{ color: colors.grey[100] }}>
                  {t("change 24h:")}
                </Typography>

                <Typography
                  variant="h6"
                  sx={{
                    color:
                      Number(curr.price_change_percentage_24h) > 0
                        ? colors.greenAccent[400]
                        : colors.redAccent[400],
                  }}
                >
                  {Number(curr.price_change_percentage_24h).toFixed(2)} %
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Coins;
