import axios from "axios";

export const TeamService = {
  async getAll() {
    const response = await axios.get(
      `${process.env.REACT_APP_STRAPI_URL}/api/users?filters[accessLevel][$null]`
    );

    const res = response.data;
    //console.log(res);

    const data = res.map((item) => ({
      id: item.id,
      username: item.username,
      firstName: item.firstName,
      lastName: item.lastName,
      age: item.age,
      email: item.email,
      avatarUrl: item.avatarUrl,
      phone: item.phone,
      accessLevel: item.accessLevel.roles.includes("admin")
        ? "admin"
        : item.accessLevel.roles.includes("accountant")
        ? "accountant"
        : item.accessLevel.roles.includes("dealer")
        ? "dealer"
        : item.accessLevel.roles.includes("manager")
        ? "manager"        
        : "user",
      createdAt: new Date(item.createdAt).toLocaleString(),
      updatedAt: new Date(item.updatedAt).toLocaleString(),
    }));

    return data;
  },

  async getById(id) {
    const response = await axios.get(
      `${process.env.REACT_APP_STRAPI_URL}/api/users/${id}`
    );
    const res = response.data;

    const _data = {
      id: res.id,
      username: res.username,
      email: res.email,
      confirmed: res.confirmed,
      blocked: res.blocked,
      avatarUrl: res.avatarUrl,
      is2fa: res.is2fa,
      isBioAuth: res.isBioAuth,
      isVerified: res.isVerified,
    };

    return _data;
  },

  async create(data, jwt) {
    const response = await axios.post(
      `${process.env.REACT_APP_STRAPI_URL}/api/users`,
      { data },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${jwt}`,
        },
      }
    );

    return response.data;
  },

  async update(id, data, jwt) {
    const response = await axios.put(
      `${process.env.REACT_APP_STRAPI_URL}/api/users/${id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${jwt}`,
        },
      }
    );

    return response.data;
  },

  async delete(id, jwt) {
    const response = await axios.delete(
      `${process.env.REACT_APP_STRAPI_URL}/api/users/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${jwt}`,
        },
      }
    );

    return response.data;
  },
};
