import { Box, CircularProgress } from "@mui/material";
import React from "react";

const LoadingBox = () => {
  return (
    <>
      <Box
        sx={{
          height: "calc(60vh - 160px)",
          minHeight: "450px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // zIndex: 1030,
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    </>
  );
};

export default LoadingBox;
