import { Box, Button, Typography } from "@mui/material";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CurrencyRateActions from "./CurrencyRateActions";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { CurrencyRateService } from "../../api/currencyRate.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CurrencyRates = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const [currencyRate, setCurrencyRate] = useState([]);
  const [rowId, setRowId] = useState();

  useEffect(() => {
    const fetchCurrencyRateData = async () => {
      const data = await CurrencyRateService.getAll();
      setCurrencyRate(data);
    };

    fetchCurrencyRateData();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "base_ccy",
      headerName: "Base ccy",
      flex: 1,
      //cellClassName: "name-column--cell",
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "flag",
      headerName: "Flag",
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <img
          alt="coin"
          width="25px"
          height="25px"
          src={`../../${params.row.flag}`}
        />
      ),
    },
    {
      field: "ccy",
      headerName: "ccy",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "buy",
      headerName: "Buy",
      flex: 1,
      headerAlign: "left",
      align: "left",
      editable: true,
      renderCell: (params) => (
        <Typography color={colors.redAccent[500]}>{params.row.buy}</Typography>
      ),
    },
    {
      field: "sale",      
      headerName: "Sale",
      flex: 1,
      headerAlign: "left",
      align: "left",
      editable: true,
      renderCell: (params) => (
        <Typography color={colors.greenAccent[500]}>
          {params.row.sale}
        </Typography>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created at",
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: "Updated at",
      flex: 1,
    },
    {
      field: "actions",
      width: 150,
      headerName: "Actions",
      type: "actions",
      renderCell: (params) => (
        <CurrencyRateActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("CURRENCY RATES")} subtitle={t("List of Currency Rates")} />

        <Box>
          <Button
            onClick={() => navigate(`/currency-rates/create`)}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <AddOutlinedIcon sx={{ mr: "10px" }} />
            {t("Create Currency Rate")}
          </Button>
        </Box>
      </Box>

      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            display: "flex",
            alignItems: "center",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-container--top [role=row]": {
            backgroundColor: `${colors.blueAccent[700]} !important`,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={currencyRate}
          columns={columns}
          getRowId={(row) => row.id}
          onCellEditStart={(params) => setRowId(params.id)}
          slots={{
            toolbar: GridToolbar,
          }}
        />
      </Box>
    </Box>
  );
};

export default CurrencyRates;
