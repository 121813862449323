import React, { useContext } from "react";
import { Button } from "@mui/material";

import { ChatContext } from "../../providers/ChatProvider";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useTranslation } from "react-i18next";

const Notifications = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { answerCall, call, callAccepted } = useContext(ChatContext);

  return (
    <>
      {call.isReceivingCall && !callAccepted && (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <h1>{call.name} {t("is calling:")}</h1>
          <Button
            variant="outlined"
            sx={{ height: "40px", marginTop: "20px", background: colors.blueAccent[600] }}
            onClick={answerCall}
          >
            {t("Answer")}
          </Button>
        </div>
      )}
    </>
  );
};

export default Notifications;
