import React, { useContext } from "react";
import { Grid, Typography, Paper } from "@mui/material";
import { styled } from "@mui/system";

import { ChatContext } from "../../providers/ChatProvider";
import { useTranslation } from "react-i18next";

const Video = styled("video")(({ theme }) => ({
  width: "550px",
  [theme.breakpoints.down("xs")]: {
    width: "300px",
  },
}));

const GridContainer = styled(Grid)(({ theme }) => ({
  justifyContent: "center",
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column",
  },
}));

const PaperStyled = styled(Paper)(({ theme }) => ({
  padding: "10px",
  border: `2px solid ${theme.palette.divider}`,
  margin: "10px",
}));

const VideoPlayer = () => {
  const { t } = useTranslation();
  const { name, callAccepted, myVideo, userVideo, callEnded, stream, call } =
    useContext(ChatContext);

  return (
    <GridContainer container>
      {stream && (
        <PaperStyled>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              {name || t("Name")}
            </Typography>
            <Video playsInline muted ref={myVideo} autoPlay />
          </Grid>
        </PaperStyled>
      )}
      {callAccepted && !callEnded && (
        <PaperStyled>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              {call.name || t("Name")}
            </Typography>
            <Video playsInline ref={userVideo} autoPlay />
          </Grid>
        </PaperStyled>
      )}
    </GridContainer>
  );
};

export default VideoPlayer;
