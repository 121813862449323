import { Box, Typography } from "@mui/material";
import Header from "../../components/Header";
import { useTranslation } from "react-i18next";

const OrderCoins = () => {
  const { t } = useTranslation();
  return (
    <Box m="20px">
      <Header
        title={t("ORDER COINS")}
        subtitle={t("List of Order Coins")}
      />
      
      <Typography variant="h3" mt="30px">
      {t("A section of the site is under development...")}
      </Typography>
    </Box>
  )
}

export default OrderCoins