import {
  Avatar,
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { AccountService } from "../../api/account.service";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const AccountOverview = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [account, setAccount] = useState();

  useEffect(() => {
    if (!id) return;
    const fetchAccountData = async (id) => {
      const data = await AccountService.getById(id);
      setAccount(data);
    };

    fetchAccountData(id);
  }, [id]);

  if (!account) return;

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ mb: 5 }}
      >
        {account?.imgsrc ? (
          <img
            alt="profile-account"
            width="50px"
            height="50px"
            src={account?.imgsrc}
            style={{ cursor: "pointer", borderRadius: "50%" }}
          />
        ) : (
          <Avatar sx={{ width: 50, height: 50 }}> </Avatar>
        )}

        <Typography mt={1}>{account.nameAccount}</Typography>
        {account.isActive ? (
          <Typography mt={1} color={colors.greenAccent[600]}>
            {t("Active")}
          </Typography>
        ) : (
          <Typography mt={1} color={colors.redAccent[600]}>
            {t("No Active")}
          </Typography>
        )}
      </Box>

      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <TextField
          fullWidth
          disabled
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          type="text"
          label={t("Id")}
          value={account?.id}
          name="id"
          sx={{ gridColumn: "span 1" }}
          readOnly={true}
        />
        <TextField
          fullWidth
          disabled
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          type="text"
          label={t("Name")}
          value={account?.nameAccount}
          name="nameAccount"
          sx={{ gridColumn: "span 1" }}
          readOnly={true}
        />
        <TextField
          fullWidth
          disabled
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          type="text"
          label={t("Coin")}
          value={account?.coin}
          name="coin"
          sx={{ gridColumn: "span 1" }}
          readOnly={true}
        />

        <TextField
          fullWidth
          disabled
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          type="text"
          label={t("Symbol")}
          value={account?.symbol}
          name="coin"
          sx={{ gridColumn: "span 1" }}
          readOnly={true}
        />

        <TextField
          fullWidth
          disabled
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          type="text"
          label={t("Number Account")}
          value={account?.numberAccount}
          name="coin"
          sx={{ gridColumn: "span 4" }}
          readOnly={true}
        />
        <TextField
          fullWidth
          disabled
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          type="text"
          label={t("Contract Address")}
          value={account?.contractAddress || ""}
          name="coin"
          sx={{ gridColumn: "span 4" }}
          readOnly={true}
        />
      </Box>

      <Divider sx={{ gridColumn: "span 4" }}></Divider>

      <Box display="flex" justifyContent="end" mt="20px" gap={2}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => navigate(-1)}
        >
         {t("Go Back")}
        </Button>
      </Box>
    </>
  );
};

export default AccountOverview;
