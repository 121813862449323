import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { useTranslation } from "react-i18next";
import ruLocale from "date-fns/locale/ru";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneIcon from "@mui/icons-material/Done";
import { useAlert } from "../../hooks/useAlert";
import { MessageService } from "../../api/message.service";
import { useAuth } from "../../providers/AuthProvider";
import { ChatContext } from "../../providers/ChatProvider";
import axios from "axios";

const Message = ({ message, isMe }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userInfo } = useAuth();
  const showAlert = useAlert();
  const { updateMessages, socket } = useContext(ChatContext);
  const [open, setOpen] = useState(false);
  const [eMessage, setEMessage] = useState(message?.attributes?.text || "");
  const [contextMenu, setContextMenu] = useState(null);

  let lang = i18n.language;

 const senderAttributes =  message?.attributes?.sender?.data.attributes

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleClickCopy = () => {
    navigator.clipboard
      .writeText(message?.attributes?.text || "")
      .then(() => showAlert(t("Copied to clipboard!"), "success"))
      .catch((err) => console.error("Failed to copy text: ", err));

    handleClose();
  };

  const handleClickEdit = () => {
    handleClickOpenDialog();
    handleClose();
  };

  const handleClickDelete = () => {
    deleteMessage(message?.id);
    handleClose();
  };

  const getMessageTime = (createdAt) => {
    return formatDistanceToNow(new Date(createdAt), {
      addSuffix: true,
      locale: lang === "ru" ? ruLocale : null,
    });
  };

  const openImageInNewTab = (imageUrl) => {
    const newWindow = window.open("", "_blank");
    newWindow.document.write(`
      <html>
        <head>
          <title>Attachment</title>
        </head>
        <body style="margin:0; display:flex; justify-content:center; align-items:center; height:100vh; background-color:#333;">
          <img src="${imageUrl}" alt="attachment" style="max-width:90%; max-height:90%;" />
        </body>
      </html>
    `);
    newWindow.document.close();
  };

  // edit message
  const editMessage = async (text) => {
    const id = message?.id;
    const data = { text: text, isRead: false };

    if (!id) return;
    try {
      const res = await MessageService.update(id, data, userInfo.jwt);
      showAlert(t("Update message successfully"), "success");

      const modifyMessage = {
        conversationId: message?.attributes.conversation.data.id,
        receiverId: message?.attributes.receiver.data.id,
        messageId: res.data.id,
        text: res.data.attributes.text,
      };

      // socket OUT
      socket.emit("updateMessage", modifyMessage);

      updateMessages();
    } catch (error) {
      console.log("Error update", error);
    }
  };

  // remove attachment file
  const removeFile = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_STRAPI_URL}/api/upload/files/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${userInfo?.jwt}`,
          },
        }
      );
    } catch (error) {
      console.log("Error removing file:", error);
    }
  };

  // delete message
  const deleteMessage = async (id) => {
    if (!id) return;

    try {
      // Remove file if it exists
      if (message?.attributes?.assetId) {
        await removeFile(message.attributes.assetId);
      }

      await MessageService.delete(id, userInfo.jwt);
      showAlert(t("Delete message successfully"), "success");

      const removeMessage = {
        conversationId: message?.attributes.conversation.data.id,
        receiverId: message?.attributes.receiver.data.id,
      };

      // socket OUT
      socket.emit("updateMessage", removeMessage);

      updateMessages();
    } catch (error) {
      console.log("Error delete", error);
    }
  };

  if (!message) return;

  return (
    <>
      {message && !isMe ? (
        <Box display="flex" mb={1}>
          <Avatar
            alt={senderAttributes?.username}
            src={senderAttributes?.avatarUrl ? `${process.env.REACT_APP_STRAPI_URL}${senderAttributes.avatarUrl}` : null}
            sx={{ mr: 1, width: 30, height: 30 }}
          />

          <Box flexDirection="column" sx={{ maxWidth: "60%" }}>
            {message?.attributes?.text && (
              <Typography
                variant="body2"
                bgcolor={colors.greenAccent[700]}
                p={1}
                sx={{ borderRadius: "10px 10px 10px 0px" }}
              >
                {message?.attributes?.text}
              </Typography>
            )}

            {message?.attributes.attachment && (
              <img
                alt="attachment"
                width={message?.attributes?.text ? "50px" : "100px"}
                src={`${process.env.REACT_APP_STRAPI_URL}${message?.attributes.attachment}`}
                style={{
                  cursor: "pointer",
                  marginTop: 6,
                  marginBottom: -6,
                }}
                onClick={() =>
                  openImageInNewTab(
                    `${process.env.REACT_APP_STRAPI_URL}${message?.attributes.attachment}`
                  )
                }
              />
            )}

            {message?.attributes.image && (
              <img
                src={`${process.env.REACT_APP_STRAPI_URL}${message?.attributes.image}`}
                alt="Sent"
                style={{ maxWidth: "100px" }}
                onClick={() =>
                  openImageInNewTab(
                    `${process.env.REACT_APP_STRAPI_URL}${message?.attributes.image}`
                  )
                }
              />
            )}

            <Typography color="grey" variant="body2">
              {message?.attributes?.createdAt &&
                getMessageTime(
                  new Date(message?.attributes?.createdAt).toUTCString()
                )}
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          <Box
            display="flex"
            mb={1}
            flexDirection="row-reverse"
            onContextMenu={handleContextMenu}
          >
            <Avatar
              alt={senderAttributes?.username}
              src={senderAttributes?.avatarUrl ? `${process.env.REACT_APP_STRAPI_URL}${senderAttributes.avatarUrl}` : null}
              sx={{ ml: 1, mr: 2, width: 30, height: 30 }}
            />

            <Box flexDirection="column" sx={{ maxWidth: "60%" }}>
              {message?.attributes?.text && (
                <Typography
                  variant="body2"
                  bgcolor={colors.blueAccent[700]}
                  p={1}
                  sx={{ borderRadius: "10px 10px 0px 10px" }}
                >
                  {message?.attributes?.text}
                </Typography>
              )}

              {message?.attributes.attachment && (
                <img
                  alt="attachment"
                  width={message?.attributes?.text ? "50px" : "100px"}
                  src={`${process.env.REACT_APP_STRAPI_URL}${message?.attributes.attachment}`}
                  style={{
                    cursor: "pointer",
                    marginTop: 6,
                    marginBottom: -6,
                  }}
                  onClick={() =>
                    openImageInNewTab(
                      `${process.env.REACT_APP_STRAPI_URL}${message?.attributes.attachment}`
                    )
                  }
                />
              )}

              {message?.attributes.image && (
                <img
                  src={`${process.env.REACT_APP_STRAPI_URL}${message?.attributes.image}`}
                  alt="Sent"
                  style={{ maxWidth: "100px" }}
                  onClick={() =>
                    openImageInNewTab(
                      `${process.env.REACT_APP_STRAPI_URL}${message?.attributes.image}`
                    )
                  }
                />
              )}

              <Typography color="grey" variant="body2" align="right">
                {message?.attributes?.createdAt &&
                  getMessageTime(
                    new Date(message?.attributes?.createdAt).toUTCString()
                  )}
              </Typography>
            </Box>
            {message &&
              (message?.attributes?.isRead ? (
                <DoneAllIcon
                  fontSize="small"
                  sx={{ color: "green", mr: "5px" }}
                />
              ) : (
                <DoneIcon fontSize="small" sx={{ color: "green", mr: "5px" }} />
              ))}
          </Box>

          {/* CONTEXT MENU */}
          <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
          >
            <MenuItem onClick={handleClickCopy}>{t("Copy message")}</MenuItem>
            <MenuItem onClick={handleClickEdit}>{t("Edit message")}</MenuItem>
            <MenuItem onClick={handleClickDelete}>
              {t("Delete message")}
            </MenuItem>
          </Menu>

          {/* DIALOG */}
          <Dialog
            open={open}
            onClose={handleCloseDialog}
            PaperProps={{
              component: "form",
              onSubmit: (event) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());
                const newMessage = formJson.eMessage;
                // console.log("eMessage:", newMessage);
                setEMessage(newMessage);
                editMessage(newMessage);
                handleCloseDialog();
              },
            }}
          >
            <DialogTitle>{t("ATTENTION!")}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t("Enter new message")}</DialogContentText>
              <TextField
                autoFocus
                value={eMessage}
                onChange={(e) => setEMessage(e.target.value)}
                margin="dense"
                id="name"
                name="eMessage"
                label={t("New message")}
                type="text"
                fullWidth
                variant="standard"
                color="secondary"
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                variant="outlined"
                onClick={handleCloseDialog}
              >
                {t("Cancel")}
              </Button>
              <Button type="submit" color="secondary" variant="contained">
                {t("Save")}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default Message;
