import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useAlert } from "../../hooks/useAlert";
import { useAuth } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { CommissionService } from "../../api/commission.service";
import { useTranslation } from "react-i18next";

const CommissionCreate = () => {
  const { t } = useTranslation();
  const { userInfo } = useAuth();
  const navigate = useNavigate();
  const showAlert = useAlert();

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = (values) => {
    console.log(values);
    createNewCommission(values);
  };

  // add new Commission
  const createNewCommission = async (data) => {
    try {
      await CommissionService.create(data, userInfo.jwt);
      showAlert(t("Create commission successfully"), "success");
      navigate("/commissions");
    } catch (error) {
      showAlert(t("Create commission error"), "error");
      console.log("Error create", error);
    }
  };

  return (
    <Box m="20px">
      <Header title={t("CREATE COMMISSION")} subtitle={t("Create a New Commission")} />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              mt="40px"
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Type")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.type}
                name="type"
                error={!!touched.type && !!errors.type}
                helperText={touched.type && errors.type}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Symbol")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.symbol}
                name="symbol"
                error={!!touched.symbol && !!errors.symbol}
                helperText={touched.symbol && errors.symbol}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Description")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Amount")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.amount}
                name="amount"
                error={!!touched.amount && !!errors.amount}
                helperText={touched.amount && errors.amount}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Currency Fee")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.currencyFee}
                name="currencyFee"
                error={!!touched.currencyFee && !!errors.currencyFee}
                helperText={touched.currencyFee && errors.currencyFee}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Percent")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.percent}
                name="percent"
                error={!!touched.percent && !!errors.percent}
                helperText={touched.percent && errors.percent}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>

            <Box display="flex" justifyContent="end" mt="20px" gap={1}>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => navigate(-1)}
              >
                {t("Go Back")}
              </Button>
              <Button type="submit" color="secondary" variant="contained">
                {t("Create New Commission")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  type: yup.string().required("required"),
  symbol: yup.string().required("required"),
  description: yup.string().required("required"),
  amount: yup.string().required("required"),
  currencyFee: yup.string().required("required"),
  percent: yup.string().required("required"),
  // isActive: yup.string().required("required"),
});

const initialValues = {
  type: "",
  symbol: "",
  description: "",
  amount: "",
  currencyFee: "",
  percent: "",
  // isActive: "",
};

export default CommissionCreate;
