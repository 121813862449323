import { Box, Button, Typography } from "@mui/material";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CoinRateActions from "./CoinRateActions";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { CoinRateService } from "../../api/coinRate.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CoinRates = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const [coinRate, setcoinRate] = useState([]);
  const [rowId, setRowId] = useState();

  useEffect(() => {
    const fetchcoinRateData = async () => {
      const data = await CoinRateService.getAll();
      setcoinRate(data);
    };

    fetchcoinRateData();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "symbol",
      headerName: "Symbol Pair",
      flex: 1,
      //cellClassName: "name-column--cell",
    },

    {
      field: "price",
      // type:"number",      
      headerName: "Price",
      flex: 1,
      headerAlign: "left",
      align: "left",
      editable: true,
      cellClassName: "name-column--cell",
      renderCell: (params) => <Typography>{params.row.price}</Typography>,
    },
    {
      field: "isActive",
      headerName: "Active",
      type: "boolean",
      flex: 1,
      editable: true,
      //cellClassName: "name-column--cell",
    },
    {
      field: "createdAt",
      headerName: "Created at",
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: "Updated at",
      flex: 1,
    },
    {
      field: "actions",
      width: 150,
      headerName: "Actions",
      type: "actions",
      renderCell: (params) => (
        <CoinRateActions {...{ params, rowId, setRowId }} />
      ),
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("COIN RATES")} subtitle={t("List of Coin Rates")} />

        <Box>
          <Button
            onClick={() => navigate(`/coin-rates/create`)}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <AddOutlinedIcon sx={{ mr: "10px" }} />
            {t("Create Coin Rate")}
          </Button>
        </Box>
      </Box>

      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            display: "flex",
            alignItems: "center",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          '.MuiDataGrid-booleanCell[data-value="true"]': {
            color: `${colors.greenAccent[500]} !important `,
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-container--top [role=row]": {
            backgroundColor: `${colors.blueAccent[700]} !important`,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={coinRate}
          columns={columns}
          getRowId={(row) => row.id}
          onCellEditStart={(params) => setRowId(params.id)}
          slots={{
            toolbar: GridToolbar,
          }}
        />
      </Box>
    </Box>
  );
};

export default CoinRates;
