import { useTheme } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { tokens } from "../../theme";
import { useNavigate, useParams } from "react-router-dom";
import { SupportTiketService } from "../../api/supportTiket.service";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const SupportHistory = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const navigate = useNavigate();
  const [tiketHistory, setHistoryTiket] = useState([]);

  const fetchSupportTiker = async () => {
    const data = await SupportTiketService.getFilterPopulate(id);
    setHistoryTiket(data);
  };

  useEffect(() => {
    if (!id) return;
    fetchSupportTiker();
  }, [id]);

  if (!tiketHistory) return;

  return (
    <>
      <Box
        gridColumn="span 4"
        gridRow="span 2"
        backgroundColor={colors.primary[400]}
        overflow="auto"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={`4px solid ${colors.primary[500]}`}
          colors={colors.grey[100]}
          p="15px"
        >
          <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
            {tiketHistory.length !== 0 ? t("Recent Tikets") : t("No tikets... ")}
          </Typography>
        </Box>

        {tiketHistory.map((item, i) => (
          <Box
            key={`${item.id}-${i}`}
            display="flex"
            justifyContent="space-between"
            borderBottom={`4px solid ${colors.primary[500]}`}
            p="15px"
          >
            <Box flex={0.2} color={colors.grey[100]}>
              {item.id}
            </Box>
            <Box flex={2}>
              <Typography
                color={colors.greenAccent[500]}
                variant="h6"
                fontWeight="600"
              >
                {item.message}
              </Typography>
            </Box>
            <Box flex={1}>
              <Typography color={colors.grey[100]}>{item?.manager}</Typography>
            </Box>
            <Box flex={1} color={colors.grey[100]}>
              {item.createdAt}
            </Box>
          </Box>
        ))}
      </Box>

      <Box display="flex" justifyContent="end" mt="20px" gap={2}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => navigate(-1)}
        >
          {t("Go Back")}
        </Button>
      </Box>
    </>
  );
};

export default SupportHistory;
