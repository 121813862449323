import { Box, Typography } from "@mui/material";
import Header from "../../components/Header";
import { useTranslation } from "react-i18next";

const Credit = () => {
  const { t } = useTranslation();
  return (
    <Box m="20px">
      <Header title= {t("CREDIT")} subtitle= {t("List of Credit")} />

      <Typography variant="h3">
      {t("A section of the site is under development...")}
      </Typography>
    </Box>
  );
};

export default Credit;
