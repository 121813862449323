import { Box, Button, IconButton, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AccountService } from "../../api/account.service";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";

const AccountHistory = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const navigate = useNavigate();
  const [transactionHistory, setTransactionHistory] = useState();
  const [account, setAccount] = useState();

  let urlBlockcypher =
    process.env.NODE_ENV === "development"
      ? `https://live.blockcypher.com/btc-testnet/tx/`
      : `https://live.blockcypher.com/btc-mainnet/tx/`;

  let urlEtherscan =
    process.env.NODE_ENV === "development"
      ? `https://sepolia.etherscan.io/tx/`
      : `https://etherscan.io/tx/`;

  useEffect(() => {
    if (!id) return;
    fetchAccountData();
    // fetchTransactionHistory();
  }, [id]);

  useEffect(() => {
    if (!account) return;
    fetchTransactionHistory();
  }, [account]);

  const fetchAccountData = async () => {
    const data = await AccountService.getById(id);
    setAccount(data);
  };

  const getTransactionBTC = async () => {
    const targetUrl =
      process.env.NODE_ENV === "development"
        ? `https://api.blockcypher.com/v1/btc/test3/addrs/${account.numberAccount}`
        : `https://api.blockcypher.com/v1/btc/main/addrs/${account.numberAccount}`; //test

    try {
      const json = await fetch(targetUrl);
      const res = await json.json();

      if (res.txrefs) {
        const resArray = res.txrefs;
        // console.log("resArray:", resArray);

        let txHistory = Object.values(
          resArray.reduce((acc, item) => {
            if (acc[item.tx_hash] && item.tx_input_n !== "0") {
              return acc;
            }

            return {
              ...acc,
              [item.tx_hash]: {
                ...item,
                id: uuid(),
                hash: item.tx_hash ? item.tx_hash : "",
                symbol: account.symbol,
                amount: Number(item.value / 1e8).toFixed(8),
                date: new Date(item.confirmed).toLocaleString(),
                type: item.tx_input_n === 0 ? "S" : "B", // S - Sold, B - Bought
                blockNumber: item.block_height,
              },
            };
          }, {})
        );

        // console.log(txHistory);
        setTransactionHistory(txHistory);
        txHistory = [];
      } else {
        setTransactionHistory([]);
      }

      // return res;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
    }
  };

  const getTransactionTokens = async () => {
    let targetUrl =
      process.env.NODE_ENV === "development"
        ? `https://api-sepolia.etherscan.io/api?module=account&action=txlist&address=${account.numberAccount}&startblock=0&endblock=99999999&page=1&offset=10&sort=desc&apikey=${process.env.REACT_APP_API_KEY_ETHERSCAN}`
        : `https://api.etherscan.io/api?module=account&action=txlist&address=${account.numberAccount}&startblock=0&endblock=99999999&page=1&offset=10&sort=desc&apikey=${process.env.REACT_APP_API_KEY_ETHERSCAN}`;

    if (account.symbol === "USDT" || account.symbol === "USDM") {
      targetUrl =
        process.env.NODE_ENV === "development"
          ? `https://api-sepolia.etherscan.io/api?module=account&action=tokentx&contractaddress=${account.contractAddress}&address=${account.numberAccount}&page=1&offset=100&startblock=0&endblock=99999999&sort=desc&apikey=${process.env.REACT_APP_API_KEY_ETHERSCAN}`
          : `https://api.etherscan.io/api?module=account&action=tokentx&contractaddress=${account.contractAddress}&address=${account.numberAccount}&page=1&offset=100&startblock=0&endblock=99999999&sort=desc&apikey=${process.env.REACT_APP_API_KEY_ETHERSCAN}`;
    }

    try {
      const json = await fetch(targetUrl);
      const res = await json.json();
      const resArray = res.result;
      // console.log(resArray);
      let txHistory = [];
      txHistory = resArray
        .filter((item) => item.value !== "0")
        .map((item) => ({
          ...item,
          id: item.timeStamp,
          hash: item.hash,
          symbol: account.symbol,
          amount:
            account.symbol === "USDM"
              ? Number(item.value / 1000000).toFixed(2)
              : Number(item.value / 1e18).toFixed(5),
          date: new Date(item.timeStamp * 1000).toLocaleString(),
          type:
            item.from === String(account.numberAccount).toLowerCase()
              ? "S"
              : "B", // S - Sold, B - Bought
          blockNumber: item.blockNumber,
          contractAddress: item.contractAddress,
          gasPrice: item.gasPrice,
          gasUsed: item.gasUsed,
          from: item.from,
          to: item.to,
          txreceipt_status: item.txreceipt_status,
        }));

      setTransactionHistory(txHistory);
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const fetchTransactionHistory = async () => {
    // console.log("acc: ", account.numberAccount);
    // console.log("symbol: ", account.symbol);
    account.symbol === "BTC" ? getTransactionBTC() : getTransactionTokens();
  };

  if (!account) return;

  if (!transactionHistory) return;
  // console.log("transactionHistory:", transactionHistory);

  if (transactionHistory.length === 0)
    return <Typography>{t("No data found...")}</Typography>;

  return (
    <>
      <Box
        gridColumn="span 4"
        gridRow="span 2"
        backgroundColor={colors.primary[400]}
        overflow="auto"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={`4px solid ${colors.primary[500]}`}
          colors={colors.grey[100]}
          p="15px"
        >
          <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
            {t("Recent Transactions")}
          </Typography>
        </Box>

        {transactionHistory.map((item, i) => (
          <Box
            key={`${item.id}-${i}`}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            p="15px"
          >
            <Box color={colors.grey[100]}>{item.date}</Box>
            <Box>
              <Typography
                color={colors.greenAccent[500]}
                variant="h5"
                fontWeight="600"
              >
                <Link
                  href={
                    item.symbol === "BTC"
                      ? `${urlBlockcypher}${item.hash}`
                      : `${urlEtherscan}${item.hash}`
                  }
                  target="_blank"
                  color={colors.greenAccent[500]}
                >
                  {item.hash}
                </Link>
              </Typography>
              {item.from && (
                <Typography color={colors.grey[100]}>
                  {t("FROM:")} {item?.from}
                </Typography>
              )}
            </Box>

            <Box
              backgroundColor={
                item.type === "B"
                  ? colors.greenAccent[500]
                  : colors.redAccent[500]
              }
              p="5px 10px"
              borderRadius="4px"
            >
              {item.amount} {item.symbol}
            </Box>

            <Box>
              <IconButton
                sx={{ fontSize: 20, width: 40, height: 40 }}
                onClick={() =>
                  navigate(`/history-details`, {
                    state: {
                      ...item,
                    },
                  })
                }
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>

      <Box display="flex" justifyContent="end" mt="20px" gap={2}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => navigate(-1)}
        >
          {t("Go Back")}
        </Button>
      </Box>
    </>
  );
};

export default AccountHistory;
