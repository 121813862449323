import { Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from "react-i18next";

const UserHistory = () => {
  const { t } = useTranslation();
  return (
    <Typography variant="h5">
      {t("A section of the site is under development...")}
      </Typography>
  )
}

export default UserHistory