import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AccountService } from "../../api/account.service";
import { Formik } from "formik";
import * as yup from "yup";
import AlertDialog from "../../components/AlertDialog";
import { useAlert } from "../../hooks/useAlert";
import { CoinContext } from "../../providers/CoinProvider";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useTranslation } from "react-i18next";

const AccountTransactions = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const navigate = useNavigate();
  const showAlert = useAlert();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [dataTransaction, setDataTransaction] = useState([]);
  const [open, setOpen] = useState(false);
  const [account, setAccount] = useState();
  const { accountsApp, coinPrice, updateCoinPrice } = useContext(CoinContext);
  const [accountId] = useState(accountsApp?.filter((c) => c.id === Number(id)));

  //fee
  const [txFeeBtc] = useState("0.0005");
  const [txFeeEth, setTxFeeEth] = useState("0.000");
  const [txFeeApp] = useState("0.00");

  const [txPriceUsd, setTxPriceUsd] = useState("0.000");

  // console.log("coinPrice: ", coinPrice);

  const handleFormSubmit = (values) => {
    console.log("values:", values);
    setDataTransaction(values);
    handleOpenDialog();
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const actions = [
    {
      label: "Cancel",
      color: "secondary",
      variant: "outlined",
      onClick: handleCloseDialog,
    },
    {
      label: "Confirm",
      color: "secondary",
      onClick: () => {
        if (
          dataTransaction.accountFrom !== undefined ||
          dataTransaction.accountTo !== undefined ||
          dataTransaction.amount !== undefined ||
          dataTransaction.symbol !== undefined
        ) {
          navigate("/confirm-transaction", {
            state: {
              accountFrom: dataTransaction.accountFrom,
              accountTo: dataTransaction.accountTo,
              contractAddress: dataTransaction.contractAddress,
              amount: dataTransaction.amount,
              cryptosymbol: dataTransaction.cryptosymbol,
              encryptPk: accountId[0].encryptPk,
              txFee: account.cryptosymbol === "BTC" ? txFeeBtc : txFeeEth,
              txFeeApp: txFeeApp,
            },
          });
        } else {
          showAlert(t("Not data... Please enter data for transaction"), "error");
        }
        handleCloseDialog();
      },
    },
  ];

  const fetchEthGasPrice = async () => {
    const targetUrl =
      process.env.NODE_ENV === "development"
        ? `https://api-sepolia.etherscan.io/api?module=proxy&action=eth_gasPrice&apikey=${process.env.REACT_APP_API_KEY_ETHERSCAN}`
        : `https://api.etherscan.io/api?module=proxy&action=eth_gasPrice&apikey=${process.env.REACT_APP_API_KEY_ETHERSCAN}`;

    try {
      const json = await fetch(targetUrl);
      const res = await json.json();
      const ethGasPrice = parseInt(res.result, 16) / 1e18;
      const limitGasETH = account?.cryptosymbol === "ETH" ? 21384 : 40000; //ETH Gas limit = 21384, token = 40000
      const tx_PriceEth = ethGasPrice * limitGasETH;
      const tx_PriceUsd = tx_PriceEth * Number(coinPrice[1].price).toFixed(2); //ethUSD
      // console.log("ethGasPrice: ", ethGasPrice);
      // console.log("txPriceEth: ", tx_PriceEth);
      // console.log("txPriceUsd: ", tx_PriceUsd);

      setTxFeeEth(tx_PriceEth);
      setTxPriceUsd(tx_PriceUsd);

      // return res;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
    }
  };

  useEffect(() => {
    if (!id) return;
    const fetchAccountData = async (id) => {
      const data = await AccountService.getById(id);
      setAccount(data);
    };

    fetchAccountData(id);    
    updateCoinPrice();
    fetchEthGasPrice();
  }, [id]);

  if (!account) return;

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={{
        accountFrom: account?.numberAccount,
        accountTo: "",
        contractAddress: account?.contractAddress || "",
        amount: "",
        cryptosymbol: account?.symbol,
      }}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Typography
            sx={{ color: colors.blueAccent[500] }}
            variant="body2"
            mb={2}
          >
            {t("Available")}: {accountId[0]?.cryptobalance}{" "}
            {accountId[0]?.cryptosymbol}
          </Typography>          
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              type="text"
              label={t("From")}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.accountFrom}
              name="accountFrom"
              error={!!touched.accountFrom && !!errors.accountFrom}
              helperText={touched.accountFrom && errors.accountFrom}
              sx={{ gridColumn: "span 4" }}
              readOnly={true}
            />

            <TextField
              fullWidth
              autoFocus
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              color="secondary"
              type="text"
              label={t("To (recipient)")}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.accountTo}
              placeholder="Enter account recipient"
              name="accountTo"
              error={!!touched.accountTo && !!errors.accountTo}
              helperText={touched.accountTo && errors.accountTo}
              sx={{ gridColumn: "span 4" }}
            />
            <TextField
              fullWidth
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              color="secondary"
              type="text"
              label={t("Contract Address")}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.contractAddress}
              placeholder={t("Enter Contract Address")}
              name="contractAddress"
              error={!!touched.contractAddress && !!errors.contractAddress}
              helperText={touched.contractAddress && errors.contractAddress}
              sx={{ gridColumn: "span 4" }}
            />

            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              color="secondary"
              type="text"
              label={t("Amount")}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.amount}
              name="amount"
              placeholder="0.00"
              error={!!touched.amount && !!errors.amount}
              helperText={touched.amount && errors.amount}
              sx={{ gridColumn: "span 1" }}
            />
            <TextField
              fullWidth
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
              color="secondary"
              type="text"
              label={t("Symbol")}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.cryptosymbol}
              name="cryptosymbol"
              error={!!touched.cryptosymbol && !!errors.cryptosymbol}
              helperText={touched.cryptosymbol && errors.cryptosymbol}
              sx={{ gridColumn: "span 1" }}
            />
          </Box>

          <Box mt="20px">
            <Typography
              sx={{ color: colors.blueAccent[500] }}
              variant="body2"
              mb={1}
            >
              {t("*Liquidity Provider Fee")} ~ {" "}
              {account?.symbol === "BTC"
                ? Number(txFeeBtc).toFixed(6) +
                  " " +
                  account?.symbol +
                  " ( " +
                  Number(txFeeBtc).toFixed(6) *
                    Number(coinPrice[0].price).toFixed(2) +
                  " $ )"
                // : account?.symbol === "USDM"
                // ? Number(txFeeUsdm).toFixed(6) +
                //   " " +
                //   account?.symbol +
                //   " ( " +
                //   Number(txFeeUsdm).toFixed(6) *
                //     Number(coinPrice[4].price).toFixed(2) +
                //   " $ )"
                : Number(txFeeEth).toFixed(6) +
                  " " +
                  "ETH" +
                  " ( " +
                  Number(txPriceUsd).toFixed(2) +
                  " $ )"}
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{ color: colors.blueAccent[500] }}
              variant="body2"
              mb={2}
            >
              {t("*Application Fee")} ~ {txFeeApp} USDM
            </Typography>
          </Box>

          <Box display="flex" justifyContent="end" mt="20px" gap={2}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => navigate(-1)}
            >
              {t("Go Back")}
            </Button>

            <Button
              type="submit"
              color="secondary"
              variant="contained"
              onClick={handleFormSubmit}
            >
              {t("Send transaction")}
            </Button>

            <AlertDialog
              open={open}
              onClose={handleCloseDialog}
              title={t("CONFIRM ACTION:")}
              content={t("Do you really want to send?")}
              actions={actions}
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};

const checkoutSchema = yup.object().shape({
  accountFrom: yup.string().required("required"),
  accountTo: yup.string().required("required"),
  amount: yup.string().required("required"),
  cryptosymbol: yup.string().required("required"),
  // contractAddress: yup.string().required("required"),
});

export default AccountTransactions;
