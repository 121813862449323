import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";


const AlertDialog = ({ open, onClose, title, content, actions }) => {

  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          {actions.map((action, index) => (
            <Button
              key={index}
              color={action.color || "primary"}
              variant={action.variant || "contained"}
              onClick={action.onClick}
              autoFocus={action.autoFocus || false}
            >
              {action.label}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AlertDialog;
