import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useAlert } from "../../hooks/useAlert";
import { useAuth } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { NotificationService } from "../../api/notification.service";
import { useTranslation } from "react-i18next";

const NotificationCreate = () => {
  const { t } = useTranslation();
  const { userInfo } = useAuth();
  const navigate = useNavigate();
  const showAlert = useAlert();

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = (values) => {
    console.log(values);
    createNewNotification(values);
  };

  // add new notification
  const createNewNotification = async (data) => {
    try {
      await NotificationService.create(data, userInfo.jwt);
      showAlert(t("Create Notification successfully"), "success");
      navigate("/notifications");
    } catch (error) {
      showAlert(t("Create Notification error"), "error");
      console.log("Error create", error);
    }
  };

  return (
    <Box m="20px">
      <Header
        title={t("CREATE NOTIFICATION")}
        subtitle={t("Create a New Notification")}
      />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
            mt="40px"
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Title")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Details")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.details}
                name="details"
                error={!!touched.details && !!errors.details}
                helperText={touched.details && errors.details}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px" gap={1}>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => navigate(-1)}
              >
                {t("Go Back")}
              </Button>
              <Button type="submit" color="secondary" variant="contained">
                {t("Create New Notification")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  title: yup.string().required("required"),
  details: yup.string().required("required"),
});

const initialValues = {
  title: "",
  details: "",
};

export default NotificationCreate;
