import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ChatContext } from "../../providers/ChatProvider";
import { useAuth } from "../../providers/AuthProvider";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MessageInput from "./MessageInput";
import Message from "./Message";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import notificationSound from "../../assets/notificationSound.mp3";

const Messages = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userInfo } = useAuth();
  const {
    selectedConversation,
    updateConversations,
    messages,
    setMessages,
    updateMessages,
    socket,
    playSound,
  } = useContext(ChatContext);
  const [status, setStatus] = useState("");
  const scrollRef = useRef();
  const selectedConversationId = selectedConversation.id;

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  //receive message and notification, socket iN
  useEffect(() => {
    if (socket === null) return;

    socket.on("getMessage", (res) => {
      //if (selectedConversationId !== res.attributes.conversation.data.id)
	  if (selectedConversationId !== res.conversationId)
        return;

      if (playSound) {
        const sound = new Audio(notificationSound);
        sound.play();
      }

      //setMessages((prev) => [...prev, res]);
      updateMessages();

      updateConversations();
    });

    socket.on("updateMessage", (res) => {
      if (selectedConversationId !== res.conversationId) return;

      updateMessages();
    });

    //130824
    socket.on("getNotification", (res) => {
      if (selectedConversationId === res.conversationId) return;

      updateConversations();
    });
	
	
	socket.on("getCleanMessages", (res) => {
      if (selectedConversationId !== res.conversationId) return;

      updateMessages();
    });
	

    return () => {
      socket.off("getMessage");
      socket.off("updateMessage");
      socket.off("getNotification");
	  socket.off("getCleanMessages");
    };
  }, [
    socket,
    selectedConversationId,
    setMessages,
    updateConversations,
    updateMessages,
    playSound,
  ]);

  //receive typing, socket iN
  useEffect(() => {
    if (socket === null) return;

    socket.on("responseTyping", (data) => {
      if (userInfo?.user.id !== data.receiverId) return;

      if (selectedConversationId !== data.conversationId) return;

      setStatus(data.status);
      setTimeout(() => setStatus(""), 1000);
    });

    return () => {
      socket.off("responseTyping");
    };
  }, [selectedConversationId, socket, userInfo?.user.id]);

  if (!messages) return;

  return (
    <>
      <Box overflow="auto" height="calc(60vh - 180px)" width="100%" mt={2}>
        {messages.length > 0 ? (
          messages.map((m, index) => (
            <div key={index} ref={scrollRef}>
              <Message
                message={m}
                isMe={m.attributes?.sender?.data?.id === userInfo?.user.id}
              />
            </div>
          ))
        ) : (
          <NoChatMessages />
        )}
      </Box>

      {status && (
        <Box
          sx={{
            position: "fixed",
            marginBottom: "10px",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              color: colors.blueAccent[400],
            }}
          >
            ...{status} {t("is typing")}
          </Typography>
        </Box>
      )}
      <MessageInput />
    </>
  );
};

export default Messages;

const NoChatMessages = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box mt="100px">
        <Box display="flex" justifyContent="center">
          <Typography color="secondary" variant="h3" textAlign="center">
            {t("No messages yet.")}
          </Typography>
        </Box>
        <Box p={1} display="flex" justifyContent="center">
          <Typography textAlign="center">
            {t("You need to write and send a message")}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <MailOutlineIcon sx={{ fontSize: "60px" }} color="secondary" />
        </Box>
      </Box>
    </>
  );
};
