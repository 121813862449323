import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserService } from "../../api/user.service";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useTranslation } from "react-i18next";

const UserOverview = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setuser] = useState();

  useEffect(() => {
    if (!id) return;
    const fetchUserData = async (id) => {
      const data = await UserService.getById(id);
      setuser(data);
    };

    fetchUserData(id);
  }, [id]);

  if (!user) return;

  return (
    <>
      <Box
        mt="30px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ mb: 5 }}
      >
        <img
          alt="profile-user"
          width="150px"
          height="150px"
          // src={`../../assets/user.png`}
          src={
            user?.avatarUrl
              ? `${process.env.REACT_APP_STRAPI_URL}${user?.avatarUrl}`
              : `../../assets/user.png`
          }
          style={{ cursor: "pointer", borderRadius: "50%" }}
        />
      </Box>

      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          color="secondary"
          type="text"
          label={t("Id")}
          value={user.id}
          name="id"
          sx={{ gridColumn: "span 2" }}
          disabled
        />
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          color="secondary"
          type="text"
          label={t("Username")}
          value={user.username}
          name="username"
          sx={{ gridColumn: "span 2" }}
          disabled
        />
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          color="secondary"
          type="email"
          label={t("Email")}
          value={user.email}
          name="details"
          sx={{ gridColumn: "span 4" }}
          disabled
        />

        <Divider sx={{ gridColumn: "span 4" }}></Divider>

        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          color="secondary"
          type="text"
          label={t("FirstName")}
          value={user?.firstName}
          name="firstName"
          sx={{ gridColumn: "span 2" }}
          disabled
        />

        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          color="secondary"
          type="text"
          label={t("LastName")}
          value={user?.lastName}
          name="lastName"
          sx={{ gridColumn: "span 2" }}
          disabled
        />

        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          color="secondary"
          type="text"
          label={t("Phone")}
          value={user?.phone}
          name="phone"
          sx={{ gridColumn: "span 2" }}
          disabled
        />
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          color="secondary"
          type="text"
          label={t("Age")}
          value={user?.age}
          name="age"
          sx={{ gridColumn: "span 2" }}
          disabled
        />

        <Divider sx={{ gridColumn: "span 4" }}></Divider>

        <FormControlLabel
          control={
            <Checkbox
              checked={user.confirmed}
              sx={{
                color: `${colors.greenAccent[800]} !important`,
                "&.Mui-checked": {
                  color: `${colors.greenAccent[600]} !important`,
                },
              }}
            />
          }
          label={t("is Confirmed")}
          name="confirmed"
          // onChange={handleChange}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={user.blocked}
              sx={{
                color: `${colors.greenAccent[800]} !important`,
                "&.Mui-checked": {
                  color: `${colors.greenAccent[600]} !important`,
                },
              }}
            />
          }
          label={t("is Blocked")}
          name="blocked"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={user.is2fa}
              sx={{
                color: `${colors.greenAccent[800]} !important`,
                "&.Mui-checked": {
                  color: `${colors.greenAccent[600]} !important`,
                },
              }}
            />
          }
          label={t("is 2FA")}
          name="is2fa"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={user.isBioAuth}
              sx={{
                color: `${colors.greenAccent[800]} !important`,
                "&.Mui-checked": {
                  color: `${colors.greenAccent[600]} !important`,
                },
              }}
            />
          }
          label={t("is BioAuth")}
          name="isBioAuth"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={user.isVerified}
              sx={{
                color: `${colors.greenAccent[800]} !important`,
                "&.Mui-checked": {
                  color: `${colors.greenAccent[600]} !important`,
                },
              }}
            />
          }
          label={t("is Verified")}
          name="isVerified"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={user.isSubChatNotification}
              sx={{
                color: `${colors.greenAccent[800]} !important`,
                "&.Mui-checked": {
                  color: `${colors.greenAccent[600]} !important`,
                },
              }}
            />
          }
          label={t("is Subscribe Chat Notification")}
          name="isSubChatNotification"
        />

        <Divider sx={{ gridColumn: "span 4" }}></Divider>
      </Box>
      <Box display="flex" justifyContent="end" mt="20px" gap={2}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => navigate(-1)}
        >
          {t("Go Back")}
        </Button>
      </Box>
    </>
  );
};

export default UserOverview;
