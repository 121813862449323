import { Box, Button, Divider, TextField, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useAuth } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { useAlert } from "../../hooks/useAlert";
import { UserService } from "../../api/user.service";
import axios from "axios";
import UploadAvatar from "./UploadAvatar";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const { t } = useTranslation();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const showAlert = useAlert();
  const { userToken } = useAuth();
  const [user, setUser] = useState();
  const [isUserUpdated, setIsUserUpdated] = useState(false);

  const handleFormSubmit = (values) => {
    console.log("values:", values);
    updateUser(values);
  };

  // update
  const updateUser = async (data) => {
    const id = JSON.parse(data.id);
    try {
      setTimeout(async () => {
        await UserService.update(id, data, userToken);
        showAlert(t("Update Profile User successfully"), "success");
        navigate("/");
      }, 1500);
    } catch (error) {
      showAlert(t("Update Profile User error"), "error");
      console.log("Error update", error);
    }
  };

  useEffect(() => {
    //get data user
    const getUserData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_STRAPI_URL}/api/users/me`,
          {
            headers: {
              Authorization: `bearer ${userToken}`,
            },
          }
        );
        setUser(data);
        setIsUserUpdated(false);
      } catch (error) {
        console.log("error:", error);
      }
    };
    getUserData();
  }, [userToken, isUserUpdated]);

  if (!user) return;

  return (
    <Box m="20px">
      <Header title={t("VIEW YOUR PROFILE")} subtitle={t("View Your Profile")} />

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ mb: 5 }}
      >
        <img
          alt="profile-user"
          width="150px"
          height="150px"
          src={
            user.avatarUrl
              ? `${process.env.REACT_APP_STRAPI_URL}${user.avatarUrl}`
              : `../../assets/user.png`
          }
          style={{ cursor: "pointer", borderRadius: "50%", marginBottom: 15 }}
        />

        <UploadAvatar
          userToken={userToken}
          userId={user.id}
          username={user.username}
          avatarUrl={user.avatartUrl}
          setIsUserUpdated={setIsUserUpdated}
        />
      </Box>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={{
          id: user?.id,
          username: user?.username,
          email: user?.email,
          password: "",
          confirm_password: "",
        }}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                type="text"
                label={t("Id")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.id}
                name="id"
                error={!!touched.id && !!errors.id}
                helperText={touched.id && errors.id}
                sx={{ gridColumn: "span 1" }}
              />
              <Divider sx={{ gridColumn: "span 4" }}></Divider>
              <TextField
                fullWidth
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                type="text"
                label={t("Username")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.username}
                name="username"
                error={!!touched.username && !!errors.username}
                helperText={touched.username && errors.username}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                type="text"
                label={t("Email")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 2" }}
              />
              <Divider sx={{ gridColumn: "span 4" }}>{t("CHANGE PASSWORD")}</Divider>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                type="password"
                label={t("New password")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.password}
                name="password"
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                type="password"
                label={t("Confirm new password")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.confirm_password}
                name="confirm_password"
                error={!!touched.confirm_password && !!errors.confirm_password}
                helperText={touched.confirm_password && errors.confirm_password}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px" gap={1}>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => navigate(-1)}
              >
                {t("Go Back")}
              </Button>
              <Button type="submit" color="secondary" variant="contained">
                {t("Update Profile User")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  username: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  password: yup.string().min(6, "Password must be 6 characters long"),
  // .matches(/[0-9]/, 'Password requires a number')
  // .matches(/[a-z]/, 'Password requires a lowercase letter')
  // .matches(/[A-Z]/, 'Password requires an uppercase letter')
  // .matches(/[^\w]/, 'Password requires a symbol'),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], 'Must match "password" field value'),
});

export default Profile;
