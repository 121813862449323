import axios from "axios";

export const CommissionService = {
  async getAll() {
    const response = await axios.get(
      `${process.env.REACT_APP_STRAPI_URL}/api/commissions`
    );

    const res = response.data.data;
    // console.log(res);

    const data = res.map((item) => ({
      id: item.id,
      symbol: item.attributes.symbol,
      type: item.attributes.type,
      category: item.attributes.category,
      description: item.attributes.description,      
      amount: item.attributes.amount,      
      percent: item.attributes.percent,      
      currencyFee: item.attributes.currencyFee,      
      isActive: item.attributes.isActive,      
      createdAt: new Date(item.attributes.createdAt).toLocaleString(),
      updatedAt: new Date(item.attributes.updatedAt).toLocaleString(),
      publishedAt: new Date(item.attributes.publishedAt).toLocaleString(),
    }));

    return data;
  },

  async getById(id) {
    const response = await axios.get(
      `${process.env.REACT_APP_STRAPI_URL}/api/commissions/${id}`
    );
    const res = response.data.data;

    const data = {     
      id: res.id,
      symbol: res.attributes.symbol,
      type: res.attributes.type,
      category: res.attributes.category,
      description: res.attributes.description,      
      amount: res.attributes.amount,      
      percent: res.attributes.percent,
      currencyFee: res.attributes.currencyFee,        
      isActive: res.attributes.isActive,
    };

    return data;
  },

  async create(data, jwt) {
    const response = await axios.post(
      `${process.env.REACT_APP_STRAPI_URL}/api/commissions`,
      { data },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${jwt}`,
        },
      }
    );

    return response.data;
  },

  async update(id, data, jwt) {   
    const response = await axios.put(
      `${process.env.REACT_APP_STRAPI_URL}/api/commissions/${id}`,
      { data },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${jwt}`,
        },
      }
    );

    return response.data;
  },

  async delete(id, jwt) {
    const response = await axios.delete(
      `${process.env.REACT_APP_STRAPI_URL}/api/commissions/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${jwt}`,
        },
      }
    );

    return response.data;
  },
};
