import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import {
  fetchAccountAppDatabase,
  fetchBinanceData,
  fetchCoinRatesAppDatabase,
} from "../api/coin.service";
import { useAuth } from "./AuthProvider";
import { useAlert } from "../hooks/useAlert";

export const CoinContext = createContext();

export const CoinProvider = ({ children }) => {
  const showAlert = useAlert();
  const { userToken } = useAuth();
  const [accountsApp, setAccountsApp] = useState([]);
  const [totalBalance, setTotalBalance] = useState(0);
  const [coinPrice, setCoinPrice] = useState([]);

  const updateAccounts = () => {
    preload();
  };

  const updateCoinPrice = async () => {
    const [res_binance, res_coinRates] = await Promise.all([
      fetchBinanceData(),
      fetchCoinRatesAppDatabase(userToken),
    ]);

    const resCoinRates = res_coinRates.map((item) => ({
      symbol: item.attributes.symbol,
      price: String(item.attributes.price),
    }));

    const resCoinPrice = res_binance.concat(resCoinRates);
    // console.log(resCoinPrice);
    setCoinPrice(resCoinPrice);
  };

  //get balance accounts
  const fetchAccountAppBalance = async (account, symbol) => {
    let coin;

    if (symbol === "USDT") {
      coin = "token";
    } else {
      coin = symbol.toLowerCase();
    }

    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL_SERVICES}/api/balance_${coin}address`,
        { account }
      )
      .then((res) => {
        // console.log(`Balance ${coin}:`, res.data);
        return res.data;
      })
      .catch((e) => {
        if (e.message === "Network Error") {
          showAlert("Network Error", "error");
        }

        console.log(`Error ${e}`);
      });
  };

  const preload = async () => {
    //fetch
    const [res_binance, res_coinRates, res_accounts] = await Promise.all([
      fetchBinanceData(),
      fetchCoinRatesAppDatabase(userToken),
      fetchAccountAppDatabase(userToken),
    ]);

    // console.log("resBinance", res_binance);
    // console.log("coinRates", res_coinRates);
    // console.log("resAccount", res_accounts);

    let resCoinRates;
    if (res_coinRates) {
      resCoinRates = res_coinRates.map((item) => ({
        symbol: item.attributes.symbol,
        price: String(item.attributes.price),
      }));
    } else {
      resCoinRates = [];
    }

    const res_price_common = res_binance.concat(resCoinRates);

    const format_price_common = res_price_common.map((item) => ({
      symbol: item.symbol.slice(0, -4), // delete USDT in pair
      price: item.price,
    }));
    // console.log("Price coin common", format_price_common);

    setCoinPrice(format_price_common);

    if (res_accounts) {
      let total = 0;

      const accountsApp = await Promise.all(
        res_accounts.map(async (item, index) => {
          const symbol = item.attributes.symbol;
          const price =
            format_price_common.find((item) => item.symbol.includes(symbol))
              ?.price || 0;

          const balance = await fetchAccountAppBalance(
            item.attributes.numberAccount,
            symbol
          );

          total += balance * price;

          return {
            id: item.id,
            name: item.attributes.coin,
            account: item.attributes.numberAccount,
            cryptobalance: balance,
            cryptosymbol: symbol,
            currency: "$",
            imgsrc: item.attributes.imgsrc,
            actualbalance: price * balance,
            percentage: "",
            decreased: true,
            difference: "8.42",
            encryptPk: item.attributes.privateKey,
            contractAddress: item.attributes.contractAddress,
            isActive: item.attributes.isActive,
            isShow:
              item.attributes.isShow !== null ? item.attributes.isShow : true,
            price: price,
          };
        })
      );

      // console.log("accountsApp:", accountsApp);

      setAccountsApp(accountsApp);
      setTotalBalance(total);
    }
  };

  useEffect(() => {
    preload();
  }, []);

  return (
    <CoinContext.Provider
      value={{
        totalBalance,
        accountsApp,
        coinPrice,
        updateAccounts,
        updateCoinPrice,
      }}
    >
      {children}
    </CoinContext.Provider>
  );
};
