import { useTheme } from "@emotion/react";
import { Check, Delete, Save } from "@mui/icons-material";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { tokens } from "../../theme";
import { CoinRateService } from "../../api/coinRate.service";
import { useAuth } from "../../providers/AuthProvider";
import { useAlert } from "../../hooks/useAlert";
import { useTranslation } from "react-i18next";

const CoinRateActions = ({ params, rowId, setRowId }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const showAlert = useAlert();
  const { userInfo } = useAuth();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleOnProcess = () => {
    deletecoinRate(rowId);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      setTimeout(async () => {
        const { id, symbol, price, isActive } = params.row;

        const data = {
          symbol,
          price,
          isActive
        };

        const result = await CoinRateService.update(id, data, userInfo.jwt);

        if (result) {
          showAlert(t("Edit Coin Rate successfully"), "success");
          setSuccess(true);
          setRowId(null);
        }
        setLoading(false);
      }, 1500);
    } catch (error) {
      showAlert(t("Edit Coin Rate error"), "error");
      console.log("error:", error);
      setLoading(false);
      setSuccess(false);
    }
  };

  // refresh
  const refreshPage = () => {
    window.location.reload(true);
  };

  // delete coinRate
  const deletecoinRate = async (id) => {
    if (!id) return;
    try {
      await CoinRateService.delete(id, userInfo.jwt);
      showAlert(t("Delete Coin Rate successfully"), "success");
      refreshPage();
    } catch (error) {
      console.log("Error delete", error);
    }
  };

  useEffect(() => {
    if (rowId === params.id && success) setSuccess(false);
  }, [rowId]);

  return (
    <>
      <Box
        sx={{
          m: 1,
          position: "relative",
        }}
      >
        {success ? (
          <Fab
            sx={{
              width: 40,
              height: 40,
              bgcolor: colors.greenAccent[500],
              "&:hover": { bgcolor: colors.greenAccent[700] },
            }}
          >
            <Check />
          </Fab>
        ) : (
          <>
            <Fab
              sx={{
                width: 40,
                height: 40,
              }}
              disabled={params.id !== rowId || loading}
              onClick={handleSubmit}
            >
              <Save />
            </Fab>

            <Fab
              sx={{
                ml: 1,
                width: 40,
                height: 40,
              }}
              disabled={params.id !== rowId || loading}
              onClick={handleClickOpenDialog}
            >
              <Delete />
            </Fab>
          </>
        )}

        {loading && (
          <CircularProgress
            size={52}
            sx={{
              color: colors.greenAccent[500],
              position: "absolute",
              top: -6,
              left: -6,
              zIndex: 1,
            }}
          />
        )}
      </Box>

      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("ATTENTION!")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("Do you really want to delete this coin rate?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleCloseDialog}
          >
            {t("CANCEL")}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => handleOnProcess()}
            autoFocus
          >
            {t("YES")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CoinRateActions;
