import { Box, Tab, Tabs} from "@mui/material";
import PropTypes from "prop-types";
import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { UserService } from "../../api/user.service";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import UserOverview from "./UserOverview";
import UserAssets from "./UserAssets";
import UserHistory from "./UserHistory";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UserView = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();  
  const [user, setUser] = useState();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!id) return;
    const fetchUserData = async (id) => {
      const data = await UserService.getById(id);
      setUser(data);
    };

    fetchUserData(id);
  }, [id]);

  if (!user) return;

  return (
    <Box m="20px">
      <Header title={t("VIEW User")} subtitle={t("View User")} />

      <Box sx={{ flex: 1 }} mt="40px">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{ background: colors.blueAccent[600] }}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab
              label={t("Overview")}
              {...tabProps(0)}
              sx={{
                "& .Mui-selected": {
                  color: `${colors.redAccent[700]} !important`,
                },
              }}
            />
            <Tab label={t("Assets")} {...tabProps(1)} />
            <Tab label={t("History")} {...tabProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <UserOverview />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <UserAssets />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <UserHistory />
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default UserView;
