import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SupportService } from "../../api/support.service";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useTranslation } from "react-i18next";

const SupportOverview = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { id } = useParams();
  const navigate = useNavigate();
  const [support, setSupport] = useState();

  useEffect(() => {
    if (!id) return;
    const fetchSupportData = async (id) => {
      const data = await SupportService.getById(id);
      setSupport(data);
    };

    fetchSupportData(id);
  }, [id]);

  if (!support) return;

  return (
    <>
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          type="text"
          label={t("Id")}
          value={support?.id}
          name="id"
          sx={{ gridColumn: "span 2" }}
          disabled
        />

        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          type="text"
          label={t("Date created")}
          value={support?.createdAt}
          name="createdAt"
          sx={{ gridColumn: "span 2" }}
          disabled
        />

        <Divider sx={{ gridColumn: "span 4" }}></Divider>

        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          type="text"
          label={t("User")}
          value={support?.userName}
          name="userName"
          sx={{ gridColumn: "span 2" }}
          disabled
        />
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          type="email"
          label={t("Email")}
          value={support?.userEmail}
          name="userName"
          sx={{ gridColumn: "span 2" }}
          disabled
        />

        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          type="text"
          label={t("Manager")}
          value={support?.manager}
          name="manager"
          sx={{ gridColumn: "span 2" }}
          disabled
        />
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          type="text"
          label={t("Status")}
          value={support?.status}
          name="status"
          sx={{ gridColumn: "span 2" }}
          disabled
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={support?.completed}
              sx={{
                color: `${colors.greenAccent[800]} !important`,
                "&.Mui-checked": {
                  color: `${colors.greenAccent[600]} !important`,
                },
              }}
            />
          }
          label={t("Completed")}
          name="completed"
          // onChange={handleChange}
        />

        <Divider sx={{ gridColumn: "span 4" }}></Divider>
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          type="text"
          label={t("Theme")}
          value={support?.themeMessage}
          name="theme"
          sx={{ gridColumn: "span 2" }}
          disabled
        />
        <TextField
          fullWidth
          multiline
          maxRows={4}
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          type="text"
          label={t("Message")}
          value={support?.userMessage}
          name="userMessage"
          sx={{ gridColumn: "span 4" }}
          disabled
        />
      </Box>

      <Box display="flex" justifyContent="end" mt="20px" gap={2}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => navigate(-1)}
        >
         {t("Go Back")}
        </Button>
      </Box>
    </>
  );
};

export default SupportOverview;
