import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";

const AccountHistotyDetail = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const location = useLocation();

  const {
    amount,
    blockHash,
    blockNumber,
    contractAddress,
    date,
    from,
    gas,
    gasPrice,
    gasUsed,
    hash,
    symbol,
    to,
    tokenDecimal,
    tokenName,
    tokenSymbol,
    type,
  } = location.state;

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("HISTORY DETAILS")} subtitle={t("List of History details")} />
      </Box>

      <Card sx={{ width: 500, background: colors.primary[400] }}>
        <CardContent>
          <Typography variant="h5" component="div">
            {t("Block Number:")}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {blockNumber}
          </Typography>

          {blockHash && (
            <>
              <Typography variant="h5" component="div">
                {t("Block Hash:")}
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {blockHash}
              </Typography>
            </>
          )}

          <Typography variant="h5" component="div">
            {t("Date:")}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {date}
          </Typography>
          <Typography variant="h5" component="div">
            {t("Hash:")}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {hash}
          </Typography>

          {from && (
            <>
              <Typography variant="h5" component="div">
                {t("From:")}
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {from}
              </Typography>
            </>
          )}

          {to && (
            <>
              <Typography variant="h5" component="div">
                {t("To:")}
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {to}
              </Typography>
            </>
          )}

          <Typography variant="h5" component="div">
            {t("Amount:")}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {amount}
          </Typography>
          <Typography variant="h5" component="div">
            {t("Symbol:")}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {symbol}
          </Typography>

          {gas && (
            <>
              <Typography variant="h5" component="div">
                {t("Gas:")}
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {gas}
              </Typography>
            </>
          )}

          {gasPrice && (
            <>
              <Typography variant="h5" component="div">
                {t("GasPrice:")}
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {gasPrice}
              </Typography>
            </>
          )}

          {gasUsed && (
            <>
              <Typography variant="h5" component="div">
                {t("Gas Used:")}
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {gasUsed}
              </Typography>
            </>
          )}

          {contractAddress && (
            <>
              <Typography variant="h5" component="div">
                {t("Contract Address:")}
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {contractAddress}
              </Typography>
            </>
          )}

          {tokenDecimal && (
            <>
              <Typography variant="h5" component="div">
                {t("Token Decimal:")}
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {tokenDecimal}
              </Typography>
            </>
          )}

          {tokenName && (
            <>
              <Typography variant="h5" component="div">
                {t("Token Name:")}
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {tokenName}
              </Typography>
            </>
          )}

          {tokenSymbol && (
            <>
              <Typography variant="h5" component="div">
                {t("Token Symbol:")}
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {tokenSymbol}
              </Typography>
            </>
          )}

          {type && (
            <>
              <Typography variant="h5" component="div">
                {t("Type:")}
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {type}
              </Typography>
            </>
          )}
        </CardContent>

        <CardActions>
          <Box display="flex" justifyContent="end" mt="10px" mb="10px" gap={2}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => navigate(-1)}
            >
              {t("Go Back")}
            </Button>
          </Box>
        </CardActions>
      </Card>
    </Box>
  );
};

export default AccountHistotyDetail;
