import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useAuth } from "../../providers/AuthProvider";
import { useNavigate, useParams } from "react-router-dom";
import { SupportService } from "../../api/support.service";
import { useAlert } from "../../hooks/useAlert";
import CustomCheckbox from "../../components/CustomCheckbox";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const SupportEdit = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userInfo } = useAuth();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { id } = useParams();
  const showAlert = useAlert();
  const [support, setSupport] = useState();

  const handleFormSubmit = (values) => {
    console.log("values:", values);
    updateSupport(values);
  };

  // update
  const updateSupport = async (data) => {
    try {
      await SupportService.update(id, data, userInfo.jwt);
      showAlert(t("Edit support successfully"), "success");
      navigate("/supports");
    } catch (error) {
      showAlert(t("Edit support error"), "error");
      console.log("Error edit", error);
    }
  };

  useEffect(() => {
    //get data Id
    const fetchSupportById = async (id) => {
      const data = await SupportService.getById(id);
      setSupport(data);
    };

    fetchSupportById(id);
  }, [id]);

  if (!support) return;

  return (
    <Box m="20px">
      <Header title={t("EDIT support")} subtitle={t("Edit support")} />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={{
          id: support.id,
          completed: support.completed || false,
          status: support.status,
          manager: support.manager,
          themeMessage: support.themeMessage,
          userMessage: support.userMessage,
        }}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
            mt="40px"
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                type="text"
                label="Id"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.id}
                // value={support.id}
                // defaultValue={support.id}
                name="id"
                error={!!touched.id && !!errors.id}
                helperText={touched.id && errors.id}
                sx={{ gridColumn: "span 2" }}
              />
              <Divider sx={{ gridColumn: "span 4" }}></Divider>

              {/* <FormControl variant="filled" sx={{ gridColumn: "span 2" }}>
                <InputLabel id="completed-label">
                  Completed
                </InputLabel>
                <Select
                  labelId="completed-label"
                  id="completed-select"
                  value={values.completed}
                  onChange={handleChange}
                  label={t("Completed")}
                  name="completed"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={true}>true</MenuItem>
                  <MenuItem value={false}>false</MenuItem>
                </Select>
              </FormControl> */}

              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                color="secondary"
                type="text"
                label={t("Manager")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.manager}
                name="manager"
                error={!!touched.manager && !!errors.manager}
                helperText={touched.manager && errors.manager}
                sx={{ gridColumn: "span 2" }}
              />

              <FormControl variant="filled" sx={{ gridColumn: "span 2" }}>
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  labelId="status-label"
                  id="status-select"
                  value={values.status}
                  onChange={handleChange}
                  label={t("Status")}
                  name="status"
                  color="secondary"
                >
                  <MenuItem value="pending">{t("pending")}</MenuItem>
                  <MenuItem value="processing">{t("processing")}</MenuItem>
                  <MenuItem value="ready">{t("ready")}</MenuItem>
                </Select>
              </FormControl>

              <CustomCheckbox
                name="completed"
                label={t("Completed")}
                colors={colors}
                onChange={handleChange}
                checked={values?.completed}
              />

              <Divider sx={{ gridColumn: "span 4" }}></Divider>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                type="text"
                label={t("Theme")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.themeMessage}
                name="title"
                error={!!touched.themeMessage && !!errors.themeMessage}
                helperText={touched.themeMessage && errors.themeMessage}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                type="text"
                label={t("Message")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.userMessage}
                name="userMessage"
                error={!!touched.userMessage && !!errors.userMessage}
                helperText={touched.userMessage && errors.userMessage}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px" gap={1}>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => navigate(-1)}
              >
                {t("Go Back")}
              </Button>
              <Button type="submit" color="secondary" variant="contained">
                {t("Save")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  themeMessage: yup.string().required("required"),
  userMessage: yup.string().required("required"),
});

export default SupportEdit;
