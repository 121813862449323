import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useAuth } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import CustomCheckbox from "../../components/CustomCheckbox";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import { AES, enc } from "crypto-js";
import { useTranslation } from "react-i18next";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="http://www.moneyshop.ua/">
        Moneyshop UA
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const SignIn = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Decrypt psw
  const getDecryptPassword = (encryptPassword) => {
    const bytes = AES.decrypt(
      encryptPassword,
      `${process.env.REACT_APP_PASSWORD}`
    );
    const decryptPassword = bytes.toString(enc.Utf8);
    // console.log("decryptPassword", decryptPassword);
    return decryptPassword;
  };

  const [email] = useState(
    localStorage.getItem("rememberMe") === "true"
      ? localStorage.getItem("email")
      : ""
  );

  const [password] = useState(() => {
    const encryptPassword =
      localStorage.getItem("rememberMe") === "true"
        ? localStorage.getItem("password")
        : "";

    return getDecryptPassword(encryptPassword);
  });

  const [rememberMe] = useState(
    localStorage.getItem("rememberMe") === "true"
      ? Boolean(localStorage.getItem("rememberMe"))
      : Boolean(false)
  );

  const { login } = useAuth();
  const navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    // console.log("values:", values);

    const identifierFormik = values.email;
    const passwordFormik = values.password;

    // Encrypt psw
    const encryptPassword = AES.encrypt(
      passwordFormik,
      `${process.env.REACT_APP_PASSWORD}`
    ).toString();
    // console.log("encryptPassword:", encryptPassword);

    const rememberMeFormik = values.rememberMe;

    localStorage.setItem("rememberMe", rememberMeFormik);
    localStorage.setItem("email", rememberMeFormik ? identifierFormik : "");
    localStorage.setItem("password", rememberMeFormik ? encryptPassword : "");

    login(identifierFormik, passwordFormik);
    navigate(`/`, { replace: true });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("Sign in")}
        </Typography>

        <Formik
          onSubmit={handleFormSubmit}
          initialValues={{
            email: email || "",
            password: password || "",
            rememberMe: rememberMe || false,
          }}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                margin="normal"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                required
                color="secondary"
                type="email"
                label={t("Email Address")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
              />

              <TextField
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                required
                fullWidth
                color="secondary"
                type="password"
                label={t("Password")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.password}
                name="password"
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
              />

              <CustomCheckbox
                name="rememberMe"
                label={t("Remember me")}
                colors={colors}
                onChange={handleChange}
                checked={values?.rememberMe}
              />

              <Button
                type="submit"
                fullWidth
                color="secondary"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {t("SIGN IN")}
              </Button>
            </form>
          )}
        </Formik>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};

const checkoutSchema = yup.object().shape({
  email: yup.string().required("required"),
  password: yup.string().min(6, "Password must be 6 characters long"),
  // .matches(/[0-9]/, 'Password requires a number')
  // .matches(/[a-z]/, 'Password requires a lowercase letter')
  // .matches(/[A-Z]/, 'Password requires an uppercase letter')
  // .matches(/[^\w]/, 'Password requires a symbol'),
});

export default SignIn;
