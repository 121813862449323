import axios from "axios";

export const SupportService = {
  async getAll() {
    const response = await axios.get(
      `${process.env.REACT_APP_STRAPI_URL}/api/supports?populate=*`
    );

    const res = response.data.data;
    //  console.log("supports:",res);

    const data = res.map((item) => ({
      id: item.id,
      userName: item.attributes.userName,
      userEmail: item.attributes.userEmail,
      themeMessage: item.attributes.themeMessage,
      userMessage: item.attributes.userMessage,
      completed: item.attributes.completed,
      manager: item.attributes.manager || "",
      status: item.attributes.status,
      supportTikets: item.attributes?.support_tikets?.data,
      feedback: item.attributes.feedback,
      createdAt: new Date(item.attributes.createdAt).toLocaleString(),
      updatedAt: new Date(item.attributes.updatedAt).toLocaleString(),
      publishedAt: new Date(item.attributes.publishedAt).toLocaleString(),
    }));

    return data;
  },

  async getById(id) {
    const response = await axios.get(
      `${process.env.REACT_APP_STRAPI_URL}/api/supports/${id}`
    );
    const res = response.data.data;

    const data = {
      id: res.id,
      userName: res.attributes.userName,
      userEmail: res.attributes.userEmail,
      themeMessage: res.attributes.themeMessage,
      userMessage: res.attributes.userMessage,
      manager: res.attributes.manager || "",
      completed: res.attributes.completed,
      status: res.attributes.status,
      feedback: res.attributes.feedback,
      createdAt: new Date(res.attributes.createdAt).toLocaleString(), 
    };

    return data;
  },

  async create(data, jwt) {
    const response = await axios.post(
      `${process.env.REACT_APP_STRAPI_URL}/api/supports`,
      { data },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${jwt}`,
        },
      }
    );

    return response.data;
  },

  async update(id, data, jwt) {   
    const response = await axios.put(
      `${process.env.REACT_APP_STRAPI_URL}/api/supports/${id}`,
      { data },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${jwt}`,
        },
      }
    );

    return response.data;
  },

  async delete(id, jwt) {
    const response = await axios.delete(
      `${process.env.REACT_APP_STRAPI_URL}/api/supports/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${jwt}`,
        },
      }
    );

    return response.data;
  },
};
