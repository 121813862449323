import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useAlert } from "../../hooks/useAlert";
import { useAuth } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UserCreate = () => {
  const { t } = useTranslation();
  const { register } = useAuth();
  const navigate = useNavigate();
  const showAlert = useAlert();

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = (values) => {
    console.log(values);
    createNewUser(values);
  };

  // add new User
  const createNewUser = async (data) => {
    try {
      await register(data.username, data.email, data.password);
      showAlert(t("Create User successfully"), "success");
      navigate("/users");
    } catch (error) {
      showAlert(t("Create User error"), "error");
      console.log("Error create", error);
    }
  };

  return (
    <Box m="20px">
      <Header title={t("CREATE User")} subtitle={t("Create a New User")} />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              mt="40px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ mb: 5 }}
            >
              <img
                alt="profile-user"
                width="150px"
                height="150px"
                src={`../../assets/user.png`}
                style={{ cursor: "pointer", borderRadius: "50%" }}
              />
            </Box>

            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Username")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.username}
                name="username"
                error={!!touched.username && !!errors.username}
                helperText={touched.username && errors.username}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="email"
                label={t("Email")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label={t("Password")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label={t("Confirm password")}
                color="secondary"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.confirm}
                name="confirm"
                error={!!touched.confirm && !!errors.confirm}
                helperText={touched.confirm && errors.confirm}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px" gap={1}>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => navigate(-1)}
              >
                {t("Go Back")}
              </Button>
              <Button type="submit" color="secondary" variant="contained">
                {t("Create New User")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  username: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  password: yup.string().min(6, "Password must be 6 characters long"),
  // .matches(/[0-9]/, 'Password requires a number')
  // .matches(/[a-z]/, 'Password requires a lowercase letter')
  // .matches(/[A-Z]/, 'Password requires an uppercase letter')
  // .matches(/[^\w]/, 'Password requires a symbol'),
  confirm: yup
    .string()
    .oneOf([yup.ref("password"), null], 'Must match "password" field value'),
});

const initialValues = {
  username: "",
  email: "",
  password: "",
  confirm: "",
};

export default UserCreate;
